<template>
  <b-sidebar
    id="upload-guideline-sidebar"
    :visible="isUploadGuidelineSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-upload-guideline-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          新增项目规则
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="add()"
          @reset.prevent="clearForm"
        >

          <b-form-group
              label="项目描述"
              label-for="description"
            >
            <b-form-textarea
              v-model = "description"
              id="textarea-default"
              rows="3"
            />
          </b-form-group>

          <b-form-group
              label="项目文档"
              label-for="guideline"
            >
            <b-form-file
              accept=".pdf"
              v-model="file"
              placeholder="请选择或拖拽文件至此（仅支持pdf格式）..."
            />
            </b-form-group>


          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :disabled="isSaving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
            保存
            </b-button>
           
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormFile,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { temp } from '@/@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    flatPickr,
    temp,
    BBadge
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isUploadGuidelineSidebarActive',
    event: 'update:is-upload-guideline-sidebar-active',
  },
  props: {
    isUploadGuidelineSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  
  data() {
    return {
      isSaving: false,
      file: null,
      description: "",
    }
  },
  methods:{
    add(){
      if(!this.file && !this.description){
        this.$toast.error('必须上传项目文档或者填写项目描述')
        return
      }
      var formData = new FormData()    
      if(this.file){
        formData.append('guideline', this.file)
      }
      formData.append('description', this.description)
      formData.append('project_id',this.$route.params.id)
      formData.append('company_id',this.projectData.company)
      this.isSaving = true
      this.$axios.post('/collab/pm/add_guideline',formData).then(res=>{
        if(res.data.status == "ok"){
          this.clearForm()
          this.$emit('refresh-guidelines')
          this.$emit('get-timeline')
          this.isSaving = false
        }else{
          this.isSaving = false
        }
      })
    },
    clearForm: function(){
      this.$emit('update:is-upload-guideline-sidebar-active', false)
      this.$refs.refFormObserver.reset()
      this.file=null
      this.description=null
    },
  },
  watch:{
    isUploadGuidelineSidebarActive: function(val){
      if(val){
        this.file=null
        this.description=""
      }
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
