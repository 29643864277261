<template>
  <b-sidebar
    id="choose-vendor-sidebar"
    :visible="isVendorSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-vendor-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          选择供应商
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="editVendors()"
          @reset.prevent="resetForm"
        >
          <validation-provider
            #default="validationContext"
            name="供应商"
          >
            <b-form-group
              label="供应商"
              label-for="vendors"
            >
            <v-select
              v-model="newVendors"
              :reduce="val => val.value"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :options="vendorOptions"
            />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
             保存
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'

export default {
  components: {
    BSpinner,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BBadge,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isVendorSidebarActive',
    event: 'update:is-vendor-sidebar-active',
  },
  props: {
    vendors: {
      type: Array,
      default: () => [],
    },
    vendorOptions: {
      type: Array,
      required: true,
      default: () => [],
    },
    isVendorSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data(){
    return{
      newVendors:[]
    }
  },
  methods:{
    editVendors: function(){
      var postData = {
        "projectId": this.$route.params.id,
        "vendorIds": []
      }
      this.newVendors.forEach(vendor => {
        postData.vendorIds.push(vendor)
      });

       this.$axios.post("/collab/pm/edit_vendors", postData).then((resp)=>{
        if(resp.data.status == "ok"){
          this.$emit('refresh-project-data')
          this.$emit('update:is-vendor-sidebar-active', false)
        }
      }) 

    },

  },
  watch:{
    "isVendorSidebarActive":function(val){
      if(val){
        this.newVendors = JSON.parse(JSON.stringify(this.vendors))
      }
    },
    vendors:{
      handler(){
        this.newVendors = JSON.parse(JSON.stringify(this.vendors))
      },
      deep:true
    }
  },
 
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
