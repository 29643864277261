<template>
  <b-sidebar
    id="edit-project-sidebar"
    :visible="isVendorContactEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-vendor-contact-edit-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          编辑供应商联络信息
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="editProject()"
          @reset.prevent="clearForm"
        >

          <validation-provider
            #default="validationContext"
            name="供应商商务联系人"
          >
            <b-form-group
              label="供应商商务联系人"
              label-for="vbm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newVendorContacts.vendorBm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contactOptions"
                :reduce="val => val.value"
                  multiple
                :clearable="true"
                input-id="vbm"
              >
              <template v-slot:option="option">
                  {{ option.label }}
                  <b-badge v-if="option.companyName || option.title" variant="light-primary">
                    {{ option.companyName + option.title }}
                  </b-badge>
                </template>
            </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="供应商项目联系人"
          >
            <b-form-group
              label="供应商项目联系人"
              label-for="vbm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newVendorContacts.vendorPm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contactOptions"
                :reduce="val => val.value"
                  multiple
                :clearable="true"
                input-id="vpm"
              >
              <template v-slot:option="option">
                  {{ option.label }}
                  <b-badge v-if="option.companyName || option.title" variant="light-primary">
                    {{ option.companyName + option.title }}
                  </b-badge>
                </template>
              </v-select>
              
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <div class="d-flex mt-2">
            <b-button
              :disabled="!axiosDone"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
             修改
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSpinner,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BBadge,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isVendorContactEditSidebarActive',
    event: 'update:is-vendor-contact-edit-sidebar-active',
  },
  props: {
    isVendorContactEditSidebarActive: {
      type: Boolean,
      default: false,
    },
    companyId: {
      type: String,
      default: null,
    },
    vendorContacts:{
      type: Object,
      default: ()=>{
        return {
          "vendorPm":[],
          "vendorBm":[],
          "wechatGroup":"",
        }
      },
    },
    contactOptions:{
      type: Array,
      default: ()=>[],
    },
  },
  data() {
    return {
      axiosDone: true,
      required,
      alphaNum,
      email,
      newVendorContacts:{
        "vendorPm":[],
        "vendorBm":[],
        "wechatGroup":"",
      },
        }
  },
  methods:{
    editProject: function(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
            this.axiosDone = false
            this.newVendorContacts.projectId = this.$route.params.id
            this.newVendorContacts.companyId = this.companyId
            this.$axios.post("collab/pm/edit_vendor_contacts", this.newVendorContacts).then((res)=>{
              this.axiosDone = true
           if (res.data.status == "ok"){
            this.clearForm()
            this.$emit("get-project")
            this.$emit('update:is-vendor-contact-edit-sidebar-active', false)
           }
          }) 
        }
      })
    
    },
    clearForm: function(){
      this.$refs.refFormObserver.reset()
      this.newVendorContacts = {}
    },
    getContactOptions(){
      var query = "?company_id=" + this.companyId
      this.$axios.get('/vendors/contacts/get_contacts' + query).then(resp =>{
        if(resp.data.status == "ok"){
          resp.data.data.contacts.forEach(
            (contact)=>{
              this.contactOptions.push({
                "value":contact.contact_id,
                "label":contact.last_name + contact.first_name,
                "companyName":contact.company_name,
                "title":contact.title
              })
            }
          )
        }
      })
    }, 
  },
  watch:{
    isVendorContactEditSidebarActive:{
      handler:function(val){
        if(val){
          this.getContactOptions()
          var copyContacts = JSON.parse(JSON.stringify(this.vendorContacts))
          this.newVendorContacts.vendorBm = copyContacts.vendor_bm
          this.newVendorContacts.vendorPm = copyContacts.vendor_pm
          this.newVendorContacts.wechatGroup = copyContacts.wechat_group
        }
      }
    },
  },
  setup() {

    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
