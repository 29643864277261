<template>
  <b-card  title="项目启动邮件">
    <b-row class="mb-1">
        <b-col>
          <div class="d-flex flex-row-reverse">
            <b-button 
              variant="primary" 
              @click="shallShowEmailComposeModal = true"
            >编写新邮件</b-button>
          </div>
        </b-col>
      </b-row>
    <b-card-body>
      
      <div class="d-flex justify-content-center mb-2" style="overflow:scroll">
        <span v-if="!sows.length">暂无数据</span>
      
      <b-table v-else :fields="fields" :items="sows">
        <template #cell(sow_name)="data">
          <a 
            class="text-primary"
            @click="loadFile(data.item.sow_id,data.item.sow_name)"
            :disabled="fileDownloading">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.sow_name }}
          </a>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="deleteSow(data.item.sow_id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>

    </b-card-body>
  </b-card>
</template>


<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge,BDropdown,BDropdownItem
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'



export default {
  components: {
    BDropdown,BDropdownItem,BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data(){
    return{
      shallShowEmailComposeModal: false,
      fileDownloading:false,
      sows:[],
      fields:[
        {"key":"sow_id","label":"任务书编号"},
        {"key":"sow_name","label":"任务书文件"},
        {"key":"actions","label":"操作"}
      ]
    }
  },
  props:{
    sowData: {
      type: Array,
      default: () => {
        return ([])
      },}
},
  methods:{
    conpose(){
      this.activeUploadSidebar()
    },
    deleteSow(sowId){
      this.$axios.get('/collab/pm/del_sow/' + sowId ).then(res=>{
        if(res.data.status == "ok"){
            this.$emit('refresh-sow')
          }
        }
      )
    },
    activeUploadSidebar(){
      this.$emit('active-upload-sow-sidebar')
    },
    loadFile(sowId,fileName){
          this.fileDownloading = true
          this.$axios.get("/collab/pm/download_sow/" + sowId).then(res=>{
            this.fileDownloading = false
            if (res.data.status == "ok"){
              const url = this.$backend + '/download_by_token/' + res.data.data.token
              const link = document.createElement('a')
              link.setAttribute('href', url)
              link.setAttribute('download', fileName)
              document.body.appendChild(link)
              link.click()
              link.remove()
            }
          })
        },
  },
  watch:{
    'sowData':{
      handler(val){
        this.sows = JSON.parse(JSON.stringify(this.sowData))
      },
      deep:true
    }
  }
}
</script>

<style>
</style>
