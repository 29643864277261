<template>
  <b-card  title="项目规则">
    <b-row class="mb-1">
        <b-col cols="12" md="auto">
          <b-alert variant="info" show>
            <div class="alert-body">
              <span>注意：在生成项目任务书时，仅会拉取最新一行的项目规则或项目描述，请确保第一行包含所有最新项目规则或描述。</span>
            </div>
          </b-alert>
        </b-col>
        <b-col>
          <div class="d-flex flex-row-reverse">
            <b-button class="ml-2"  variant="primary" @click="activeUploadSidebar">新增</b-button>
          </div>
        </b-col>
      </b-row>
    <b-card-body>
      

      <div class="d-flex justify-content-center mb-2" style="overflow:scroll" v-if="!guidelines.length">
        <span >暂无数据</span>
      </div>

      <div class="table-responsive" v-else>
        <b-table :fields="fields" :items="guidelines" class="text-nowrap">
          <template #cell(guideline_name)="data">
            <a v-if="data.item.guideline_name && data.index == 0"
              class="text-primary"
              @click="loadFile(data.item.guideline_id,data.item.guideline_name)">
              <font-awesome-icon 
                icon="fa-solid fa-paperclip"
              />
              {{ data.item.guideline_name }}
            </a>

            <a v-else-if="data.item.guideline_name && data.index !=0"
              style="text-decoration:line-through"
              class="text-primary"
              @click="loadFile(data.item.guideline_id,data.item.guideline_name)">
              <font-awesome-icon 
                icon="fa-solid fa-paperclip"
              />
              {{ data.item.guideline_name }}
            </a>
          </template>

          <template #cell(description)="data">
            <p class="text-wrap" v-if="data.index == 0">{{ data.item.description }}</p>

            <p v-else style="text-decoration:line-through">{{ data.item.description }}</p>
          </template>


          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item
                @click="deleteGuideline(data.item.guideline_id)"
              >
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </div>

    </b-card-body>
  </b-card>
</template>


<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge,BDropdown,BDropdownItem,BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'


export default {
  components: {
    BAlert,BDropdown,BDropdownItem,BCard, BTable, BCardBody, BCardTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data(){
    return{
      guidelines:[],
      fields:[
        //{"key":"guideline_id","label":"项目规则编号"},
        {"key":"user_name","label":"上传用户"},
        {"key":"guideline_name","label":"项目文档文件"},
        {"key":"description","label":"项目描述"},
        //{"key":"actions","label":"操作"}
      ]
    }
  },
  props:{
    guidelineData: {
      type: Array,
      default: () => {
        return ([])
      },}
},
  methods:{
    deleteGuideline(guidelineId){
      this.$axios.get('/collab/pm/del_guideline/' + guidelineId ).then(res=>{
          if(res.data.status == "ok"){
            this.$emit('refresh-guidelines')
          }
        }
      )
    },
    activeUploadSidebar(){
      this.$emit('active-upload-guideline-sidebar')
    },
    loadFile(guidelineId,fileName){
      this.$axios.get("/collab/pm/download_guideline/" + guidelineId).then(res=>{
          if(res.data.stauts = "ok"){
            const url = this.$backend + '/download_by_token/' + res.data.data.token
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', fileName )
            document.body.appendChild(link)
            link.click()
            link.remove() 
          }
        })    
    },
    openPdf(guidelineId){
      this.$axios.get("/collab/pm/download_guideline/" + guidelineId).then(res=>{
          if(res.data.stauts = "ok"){

          }
        })    
      },
  },
  watch:{
    'guidelineData':{
      handler(val){
        this.guidelines = JSON.parse(JSON.stringify(this.guidelineData))
      },
      deep:true
    }
  }
}
</script>

<style>

</style>
