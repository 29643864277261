<template>
  <div>
  <b-card-actions class="b-card" :title="vendorData.name" action-collapse>
    <b-badge variant="light-success" v-if="contractValid">
      合同有效
    </b-badge>
    <b-badge variant="light-danger" v-else>
      合同无效
    </b-badge>

    <b-tabs align="center" pills>
    <b-tab
    title="定价单"
    active>
    <b-card-body>
      <cost-tab
        :has-bm-price-data="hasBmPriceData"
        :same-with-raw-data="sameWithRawData"
        :raw-data = "rawData"
        :cost-table-data="costTableData"
        @save-cost-data = saveCostData
        @align-newest-price = alignNewestPrice
      />
    </b-card-body>
  </b-tab>


  <b-tab
    title="联络信息">
  <contact-tab
    :contact-options = contactOptions
    :filtered-vendor-contacts = filteredVendorContacts
    @active-vendor-contact-edit-sidebar = activeVendorContactEditSidebar
  />
  </b-tab>

  <b-tab
    title="任务书">
    <sow-tab
      :has-valid-contract="contractValid"
      :has-cost-data="hasCostData"
      :has-guideline="hasGuideline"
      :has-vendor-bm="hasVendorBm"
      :has-vendor-pm="hasVendorPm"
      :company-id="companyId"
      :sows="sows"
      @gen-sow="genSow"
      @get-vendor-sows="getVendorSows($event)"
      @active-edit-sow-sidebar="isEditSowSidebarActive = true"
      @set-editing-sow-data="setEditingSowData($event)"
    />
  </b-tab>

  <b-tab
    title="结算单">
    <statement-tab
      :users="users"
      :has-sow="hasSow"
      :statements="statements"
      @get-vendor-statements="getVendorStatements()"
      @gen-statement="genStatement"
      @active-edit-statement-sidebar="isEditStatementSidebarActive = true"
      @raise-payment-approval="raisePaymentApproval($event)"
      @set-editing-statement-data="setEditingStatementData($event)"
    />
  </b-tab>

  </b-tabs>
  </b-card-actions>

  <vendor-contact-edit-sidebar
    :is-vendor-contact-edit-sidebar-active.sync="isVendorContactEditSidebarActive"
    :company-id = companyId
    :contact-options = contactOptions
    :vendor-contacts = filteredVendorContacts
    @get-project = getProject
  />

  <edit-sow-sidebar
    :is-edit-sow-sidebar-active.sync="isEditSowSidebarActive"
    :sow-data = editingSowData
    @get-vendor-sows="getVendorSows"
  />

  <edit-statement-sidebar
    :is-edit-statement-sidebar-active.sync="isEditStatementSidebarActive"
    :statement-data = editingStatementData
    @get-vendor-statements="getVendorStatements"
  />

  <raise-payment-sidebar
    :is-raise-payment-sidebar-active.sync="isRaisePaymentSidebarActive"
    :raising-statement-id = raisingStatementId
    :controls = raisePaymentControls
    :payment-data="paymentData"
    @refresh-statements="getVendorStatements"
  />

</div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import BEditableTable from "bootstrap-vue-editable-table";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VendorContactEditSidebar from './VendorContactEditSidebar.vue'
import EditSowSidebar from './EditSowSidebar.vue'
import CostTab from './CostTab.vue'
import ContactTab from './ContactTab.vue'
import SowTab from './SowTab.vue'
import StatementTab from './StatementTab.vue'
import EditStatementSidebar from './EditStatementSidebar.vue'
import RaisePaymentSidebar from './RaisePaymentSidebar.vue'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BCard,
  BDropdown,
  BCardBody,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BBadge,
  BTab,
  BTabs,
  BAlert,
  
} from "bootstrap-vue";
export default {
  components: {
    VendorContactEditSidebar,
    BBadge,
    BCardBody,
    BFormGroup,
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    AppCollapseItem,
    AppCollapse,
    BCardActions,
    BFormInput,
    BTab,
    BTabs,
    BAlert,
    CostTab,
    ContactTab,
    SowTab,
    EditSowSidebar,
    StatementTab,
    EditStatementSidebar,
    RaisePaymentSidebar,
  },
  data() {
    return {
      fields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "原成本单价（元）",
        },
        {
          key: "price",
          label: "原报价单价（元）",
        },
        {
          key: "profit",
          label: "原利润率（%）",
        },
        {
          key: "nowCost",
          label: "现成本单价（元）",
        },
        {
          key: "nowProfit",
          label: "现利润率（%）",
        },
        {
          key: "note",
          label: "备注",
        },
        { key: "edit", 
        label: "操作" },
      ],
      historyFields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "原成本单价（元）",
        },
        {
          key: "price",
          label: "原报价单价（元）",
        },
        {
          key: "profit",
          label: "原利润率（%）",
        },
        {
          key: "nowCost",
          label: "现成本单价（元）",
        },
        {
          key: "nowProfit",
          label: "现利润率（%）",
        },
        {
          key: "note",
          label: "备注",
        },
      ],
      rawData:{
        newest_cost: {
          "time_cn":""
        },
        history_costs: [],
      },
      costTableData:  {
          newest_cost: {
            cost_table:[],
            time_cn: '',
          },
          history_costs: [],
      },
      raisingStatementId: "",
      vendorData:{},
      contractValid: false,
      sameWithRawData: true,
      isVendorContactEditSidebarActive:false,
      isEditSowSidebarActive:false,
      isRaisePaymentSidebarActive:false,
      isEditStatementSidebarActive:false,
      contactOptions:[],
      sows:[],
      statements:[],
      editingSowData:{
        "sowId":"",
        "sowName":"",
        "signStatus":"",
      },
      editingStatementData: {
        "statementId":"",
        "statementName":"",
        "signStatus":"",
        "invoiceId":"",
        "invoiceName":"",
        "invoiceStatus":"",
      },
      raisePaymentControls:[],
      paymentData:{
        "summary":null,
      },
    }
  },
  props:{
    users:{
      type: Array,
      default: function(){
        return []
      }
    },
    companyId:{
      type: String,
      default: ""
    },
    newestPriceData:{
      type: Array,
      default: function(){
        return []
      }
    },
    vendorContacts:{
      type: Object,
      default: function(){
        return {}
      }
    },
    hasGuideline:false
  },
  methods: {
    getVendorSows(companyId){
      this.$axios.get('/collab/pm/get_vendor_sows/' +this.$route.params.id + '/' + companyId).then(resp=>{
        if(resp.data.status == "ok"){
          this.sows = resp.data.data.sows
        }
      })
    },
    raisePaymentApproval(data){
      this.raisingStatementId = data.statementId
      this.paymentData.summary = data.summary
      this.getPaymentApproval()
      this.getCompanyInfoAndActiveRaisePaymentSidebar(data.companyId) 
    },
    getPaymentApproval(){
      this.$axios.get('/collab/approvals/get_raise_vendor_payment_approval').then(resp =>{
        if(resp.data.status == "ok"){
          this.raisePaymentControls = resp.data.data.approval[0].controls
        }
      })
    },
    getCompanyInfoAndActiveRaisePaymentSidebar(companyId){
      this.$axios.get('/vendors/companies/get_company/' + companyId).then(resp=>{
        if(resp.data.status == "ok"){
          this.paymentData = {...resp.data.data.company, ...this.paymentData}
          this.isRaisePaymentSidebarActive = true
        }
      })
    },
    setEditingStatementData(statementData){
      this.editingStatementData = JSON.parse(JSON.stringify(statementData))
    },
    setEditingSowData(sowData){
      this.editingSowData = JSON.parse(JSON.stringify(sowData))
    },
    activeVendorContactEditSidebar(){
      this.isVendorContactEditSidebarActive = true
    },
/*     getContactOptions(){
      this.$axios.get('/vendors/contacts/get_contacts').then(resp =>{
        if(resp.data.status == "ok"){
          resp.data.data.contacts.forEach(
            (contact)=>{
              this.contactOptions.push({
                "value":contact.contact_id,
                "label":contact.last_name + contact.first_name,
                "companyName":contact.company_name,
                "title":contact.title
              })
            }
          )
        }
      })
    }, */
    genSow(){
      this.$router.push({name: "collab-pm-vendor-sow-view", params: {id: this.$route.params.id, companyId: this.companyId}})
    },
    genStatement(){
      this.$router.push({name: "collab-pm-vendor-statement-view", params: {id: this.$route.params.id, companyId: this.companyId}})
    },
    getVendorData(){
      this.$axios.get("/vendors/companies/get_company/" + this.companyId)
        .then(resp => {
          this.vendorData = resp.data.data.company
        })
    },
    computeRemainDays: function(dueDate){
      let due = new Date(dueDate)
      let now = new Date()
      let remain = due - now
      let days = Math.floor(remain / (24 * 3600 * 1000))
      return days
    },
    getContracts(){
      this.$axios.get("/vendors/companies/get_contracts/" + this.companyId)
        .then(resp => {
          var contracts = resp.data.data.contracts
          if(!contracts.length) this.contractValid = false

          contracts.forEach((item)=>{
            var remainingDays = this.computeRemainDays(item.contract_due_date)
            if(remainingDays >= 0 && item.sign_status){
              this.contractValid = true
              return
            }
          })
        })
    },
    getVendorCost(){
      this.$axios.get("/collab/pm/get_vendor_cost_table/" + this.$route.params.id + "/"  + this.companyId )
        .then(resp => {
          this.rawData = JSON.parse(JSON.stringify(resp.data.data.costs))
          this.costTableData = JSON.parse(JSON.stringify(resp.data.data.costs))
        })
    },
    getVendorSows(){
      this.$axios.get('/collab/pm/get_vendor_sows/' + this.$route.params.id + "/"  + this.companyId).then(resp =>{
        this.sows = resp.data.data.sows
      })
    },
    getVendorStatements(){
      this.$axios.get('/collab/pm/get_vendor_statements/' + this.$route.params.id + "/"  + this.companyId).then(resp =>{
        this.statements = resp.data.data.statements
      })
    },
    alignNewestPrice(){
      var priceData = JSON.parse(JSON.stringify(this.newestPriceData))
      priceData.forEach(item=>{
        item.id = Math.random() + Math.random()
      })
      this.costTableData.newest_cost.cost_table = priceData
    },
/*     delRow(id){
      this.costTableData.newest_cost.cost_table = this.costTableData.newest_cost.cost_table.filter((item)=>{
        return item.id !== id
      })
    }, */
    saveCostData(){
      var data = {
        companyId: this.companyId,
        projectId: this.$route.params.id,
        costTable: this.costTableData.newest_cost.cost_table
      }
      this.$axios.post("/collab/pm/add_vendor_cost_table",data)
        .then(resp => {
          if(resp.data.status=="ok"){
            this.getVendorCost()
          }
        })
      },
    editContactInfo(){
    },
    contactMapping(contactId){
      var contact = this.contactOptions.find((contact)=>{
        return contact.value == contactId
      })
      if(contact){
        return contact.label
      }
    },
    getProject(){
      this.$emit('get-project')
    }
  },
  computed:{
    filteredVendorContacts(){
      return this.vendorContacts[this.companyId] ? this.vendorContacts[this.companyId] : {
        vendor_bm:[],
        vendor_pm:[],
      }
    },
    hasBmPriceData(){
      if(this.newestPriceData.length){
        return true
      }else{
        return false
      }
    },
    hasCostData(){
      if(this.costTableData.newest_cost.cost_table.length){
        return true
      }else{
        return false
      }
    },
    hasVendorBm(){
        if(this.filteredVendorContacts.vendor_bm.length){
          return true
        }else{
          return false
      }
    
    },
    hasVendorPm(){
      if(this.filteredVendorContacts.vendor_pm.length){
        return true
      }else{
        return false
      }
    },
    hasSow(){
      if(this.sows.length){
        return true
      }else{
        return false
      }
    },
    
  },
  watch:{
    "costTableData":{
      handler: function(){
        if(JSON.stringify(this.costTableData.newest_cost.cost_table) === JSON.stringify(this.rawData.newest_cost.cost_table)){
          this.sameWithRawData = true
        }else{
          this.sameWithRawData = false
        }
        },
      deep: true
    },
  },
  mounted(){
      //this.getContactOptions()
      this.getVendorData()
      this.getContracts()
      this.getVendorCost()
      this.getVendorSows()
      this.getVendorStatements()  
    
  }
};
</script>

<style>

.table-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px
}
</style>