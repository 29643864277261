<template>
  <div>
      <b-row class="mb-1">
        <b-col>
          <span class="mr-1">最新定价单 {{ rawData.newest_cost.time_cn }}</span>
        </b-col>
        <b-col>
          <div class="d-flex flex-row-reverse">
            <b-button @click="$emit('save-cost-data')" v-if="!sameWithRawData && costTableData.newest_cost.cost_table.length" variant="primary" class="ml-2">保存</b-button>
          </div>
        </b-col>
      </b-row>

      <div class="table-responsive">
        <b-table 
          v-if="costTableData.newest_cost.cost_table.length"
          :bordered="true"
          :items="costTableData.newest_cost.cost_table"
          :fields="fields"
        >
        <template #cell(profit)="data">
          <b-badge v-if="data.item.price && data.item.cost" variant="light-primary">
            {{ ((data.item.price - data.item.cost)/data.item.price * 100).toFixed(2) }}%
          </b-badge>
        </template>

        <template #cell(nowCost)="data">
          <b-form-input no-wheel class="table-input" v-model="data.item.nowCost" type="number"/>
        </template>

        <template #cell(nowProfit)="data">
          <b-badge v-if="data.item.price && data.item.nowCost" variant="light-primary">
            {{ ((data.item.price - data.item.nowCost)/data.item.price * 100).toFixed(2) }}%
          </b-badge>
        </template>

        <template #cell(note)="data">
          <b-form-input class="table-input" v-model="data.item.note" type="text"/>
        </template>

        <template #cell(edit)="data">
              <b-dropdown
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>

                  <b-dropdown-item @click="">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">删除</span>
                  </b-dropdown-item>


              </b-dropdown>
            </template>
      </b-table>
      </div>


      <div class="d-flex justify-content-center" style="overflow:scroll" v-if="hasBmPriceData">
        <p v-if="!costTableData.newest_cost.cost_table.length">暂无数据，</p>
        <a v-if="!costTableData.newest_cost.cost_table.length" @click="$emit('align-newest-price')" class="text-primary">拉取最新报价单数据</a>
      </div>
      
        <div class="d-flex justify-content-center mb-2" v-else>
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body">
                <span>请先添加报价评估单</span>
              </div>
            </b-alert>
          </div>    

      <hr>
      <span>历史定价单</span>
      <app-collapse v-if = "rawData.history_costs.length" accordion type="margin">
        <template v-for="(item,index) in rawData.history_costs">
        <app-collapse-item :title="item.time_cn">
        <div class="table-responsive" style="overflow:scroll">
        <b-table 
            :bordered="true"
            responsive="sm"
            :items="rawData.history_costs[index].cost_table"
            :fields="historyFields"
          >
          <template #cell(profit)="data">
            <b-badge v-if="data.item.price && data.item.cost" variant="light-primary">
              {{ ((data.item.price - data.item.cost)/data.item.price * 100).toFixed(2) }}%
            </b-badge>
          </template>

          <template #cell(nowProfit)="data">
            <b-badge v-if="data.item.price && data.item.nowCost" variant="light-primary">
              {{ ((data.item.price - data.item.nowCost)/data.item.price * 100).toFixed(2) }}%
            </b-badge>
          </template>
        </b-table>
        </div>
      </app-collapse-item>
      </template>
      </app-collapse>
      <div v-else class="d-flex justify-content-center" style="overflow:scroll">
        <p>暂无数据</p>
      </div>


</div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import BEditableTable from "bootstrap-vue-editable-table";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VendorContactEditSidebar from './VendorContactEditSidebar.vue'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BCard,
  BDropdown,
  BCardBody,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BBadge,
  BTab,
  BTabs,
  BAlert
  
} from "bootstrap-vue";
export default {
  components: {
    VendorContactEditSidebar,
    BBadge,
    BCardBody,
    BFormGroup,
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    AppCollapseItem,
    AppCollapse,
    BCardActions,
    BFormInput,
    BTab,
    BTabs,
    BAlert
  },
  data() {
    return {
      fields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "原成本单价（元）",
        },
        {
          key: "price",
          label: "原报价单价（元）",
        },
        {
          key: "profit",
          label: "原利润率（%）",
        },
        {
          key: "nowCost",
          label: "现成本单价（元）",
        },
        {
          key: "nowProfit",
          label: "现利润率（%）",
        },
        {
          key: "note",
          label: "备注",
        },
        { key: "edit", 
        label: "操作" },
      ],
      historyFields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "原成本单价（元）",
        },
        {
          key: "price",
          label: "原报价单价（元）",
        },
        {
          key: "profit",
          label: "原利润率（%）",
        },
        {
          key: "nowCost",
          label: "现成本单价（元）",
        },
        {
          key: "nowProfit",
          label: "现利润率（%）",
        },
        {
          key: "note",
          label: "备注",
        },
      ],

    }
  },
  props:{
    hasBmPriceData:{
      type: Boolean,
      default: false
    },
    costTableData: {
      type: Object,
      default: () => {
        return {
          newest_cost: {
            cost_table:[],
            time_cn: '',
          },
          history_costs: [],
        }
      }
    },
    sameWithRawData: {
      type: Boolean,
      default: false
    },
    rawData: {
      type: Object,
      default: () => {
        return {
          newest_cost: {
            time_cn: '',
          },
          history_costs: [],
        }
      }
    },
  
  },

};
</script>

<style>

.table-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px
}
</style>