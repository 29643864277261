<template>
    <b-card-actions  action-collapse title="项目任务书">
    <template v-if="this.hasPriceData && this.hasContract && this.hasGuideline && this.hasCustomerBm && this.hasCustomerPm">
    <b-row class="mb-1">
        <b-col>
          <div class="d-flex flex-row-reverse">
            <!-- <b-button class="ml-2"  variant="primary" @click="activeUploadSidebar">上传已签署的任务书</b-button> -->  
          
          <b-button variant="primary" @click="genSow">生成任务书</b-button>
          </div>
        </b-col>
      </b-row>
    <b-card-body>
      
      <div class="d-flex justify-content-center mb-2" style="overflow:scroll" v-if="!sows.length">
        <span >暂无数据</span>
      </div>

      <div v-else class="table-responsive">
      <b-table :fields="fields" :items="sows" class="text-nowrap">
        <template #cell(sow_name)="data">
          <a 
            :disabled= "fileDownloading"
            v-b-tooltip.hover.right="data.item.sow_name"
            class="text-primary"
            @click="loadFile(data.item.sow_id,data.item.sow_name)">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.sow_name.slice(0, 10) + '...' }}
          </a>
        </template>
      
        <template #cell(email_status)="data">
          <b-badge v-if="data.item.email_status" variant="light-success">
            已发送
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未发送
          </b-badge>
        </template>

        <template #cell(sign_status)="data">
          <b-badge v-if="data.item.sign_status" variant="light-success">
            双方已签署
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未经双方签署
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="sendLaunchMail(data.item.sow_id)"
            >
              <feather-icon icon="MailIcon" />
              <span class="align-middle ml-50">发送启动邮件</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="activeEditSidebar({
                sowId: data.item.sow_id,
                sowName: data.item.sow_name,
                deliveryCode: data.item.delivery_code,
                signStatus: data.item.sign_status
              })"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteSow(data.item.sow_id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    
    </b-card-body>
  </template>


  <template v-else>
    <b-card-body>
      <div class="d-flex justify-content-center mb-2">
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body">
                <span v-if="!this.hasContract">请先绑定框架合同</span>
                <span v-else-if="!this.hasGuideline">请先添加项目规则</span>
                <span v-else-if="!this.hasPriceData">请先添加报价评估单</span>
                <span v-else-if="!this.hasCustomerBm">请先添加客户商务联系人</span>
                <span v-else-if="!this.hasCustomerPm">请先添加客户项目联系人</span>
              </div>
            </b-alert>
          </div>    
        </b-card-body>
  </template>


 
  </b-card-actions>
</template>


<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge,BDropdown,BDropdownItem,BModal, VBModal, BFormGroup,BAlert,VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'


export default {
  components: {
    BAlert,BFormGroup,BCardActions,BDropdown,BDropdownItem,BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge,BModal
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data(){
    return{
      fileDownloading:false,
      sows:[],
      fields:[
        //{"key":"sow_id","label":"任务书编号"},
        {"key":"user_name","label":"上传用户"},
        {"key":"sow_name","label":"任务书文件"},
        {"key":"delivery_code","label":"快递单号"},
        {"key":"sign_status","label":"双方签署状态"},
        {"key":"email_status","label":"邮件发送状态"},
        {"key":"actions","label":"操作"}
      ]
    }
  },
  props:{
    sowData: {
      type: Array,
      default: () => {
        return ([])
      },
    },
    hasPriceData: false,
    hasContract: false,
    hasGuideline:false,
    hasCustomerPm:false,
    hasCustomerBm:false,
},
  methods:{
    genSow(){
      this.$router.push({name: "collab-pm-sow-view", params: {id: this.$route.params.id}})
    },
    deleteSow(sowId){
      this.$axios.get('/collab/pm/del_sow/' + sowId ).then(res=>{
        if(res.data.status == "ok"){
            this.$emit('refresh-sow')
          }
        }
      )
    },
    activeEditSidebar(sowData){
      this.$emit('active-edit-sow-sidebar')
      this.$emit('set-editing-sow-data',sowData)
    },
    loadFile(sowId,fileName){
      this.fileDownloading = true
        this.$axios.get("/collab/pm/download_sow/" + sowId).then(res=>{
          this.fileDownloading = false
          if(res.data.status == "ok"){
            const url = this.$backend + '/download_by_token/' + res.data.data.token
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
        })
    },
    sendLaunchMail(sowId){
      this.$router.push({name: "collab-pm-launch-mail-view", params: {id: this.$route.params.id ,sowId: sowId}})
    },
  },
  watch:{
    'sowData':{
      handler(val){
        this.sows = JSON.parse(JSON.stringify(this.sowData))
      },
      deep:true
    }
  }
}
</script>