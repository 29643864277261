<template>
  <b-sidebar
    id="edit-project-sidebar"
    :visible="isEditProjectSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-edit-project-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          编辑项目
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="editProject()"
          @reset.prevent="clearForm"
        >
          <!-- 项目名称 -->
          <validation-provider
            #default="validationContext"
            name="项目名称"
            rules="required"
          >
            <b-form-group
              label="项目名称"
              label-for="project-name"
            >
              <b-form-input
                id="project-name"
                v-model="newProjectData.project_name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="公司"
            rules=""
          >
            <b-form-group
              label="公司"
              label-for="company"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newProjectData.company"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="companyOptions"
                :reduce="val => val.value"
                :clearable="true"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            v-show="newProjectData.company"
            #default="validationContext"
            name="合同"
          >
            <b-form-group
              label="合同"
              label-for="contract"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newProjectData.contract"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="contractOptions"
                :reduce="val => val.value"
                :clearable="true"
                input-id="contract">
                <template v-slot:option="option">
                  {{ option.label }}
                  <br>
                  <b-badge  v-if="option.remain >= 0" variant="light-success">
                    剩余{{ option.remain }}天
                  </b-badge>
                  <b-badge v-else variant="light-danger">
                    合同已过期
                  </b-badge>
                  <b-badge v-if="!option.signStatus" variant="light-warning" class="ml-1">
                    未经双方签署
                  </b-badge>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          
          <validation-provider
            v-if="newProjectData.company"
            #default="validationContext"
            name="客户商务联系人"
          >
            <b-form-group
              label="客户商务联系人"
              label-for="customerBm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newProjectData.customer_bm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customerOptions"
                :reduce="val => val.value"
                multiple
                :clearable="true"
              >
              <template v-slot:option="option">
                  {{ option.label }}
                  <b-badge v-if="option.company || option.title" variant="light-primary">
                    {{ option.company + option.title}}
                  </b-badge>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
          v-if="newProjectData.company"

            #default="validationContext"
            name="客户项目联系人"
            rules=""
          >
            <b-form-group
              label="客户项目联系人"
              label-for="customer-pm"
              :state="getValidationState(validationContext)"
            >
            <v-select
                v-model="newProjectData.customer_pm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="customerOptions"
                :reduce="val => val.value"
                multiple
                :clearable="true"
              >
              <template v-slot:option="option">
                  {{ option.label }}
                  <b-badge v-if="option.company || option.title" variant="light-primary">
                    {{ option.company + option.title}}
                  </b-badge>
                </template>
              </v-select>
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="商务经理"
            rules="required"
          >
            <b-form-group
              label="商务经理"
              label-for="bm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newProjectData.bm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                :reduce="val => val.value"
                multiple
                :clearable="true"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="项目经理"
            rules="required"
          >
            <b-form-group
              label="项目经理"
              label-for="bm"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="newProjectData.pm"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                :reduce="val => val.value"
                multiple
                :clearable="true"
                input-id="user-role"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <div class="d-flex mt-2">
            <b-button
              :disabled="!isSaving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
             保存
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

export default {
  components: {
    BSpinner,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BBadge,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditProjectSidebarActive',
    event: 'update:is-edit-project-sidebar-active',
  },
  props: {
    contractOptions:{
      type: Array,
      required: true,
      default: () => ([]),
    },
    isEditProjectSidebarActive: {
      type: Boolean,
      required: true,
    },
    projectData: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    userOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    companyOptions: {
      type: Array,
      required: true,
      default: () => ([]),
    },
  },
  data() {
    return {
      isSaving: true,
      required,
      alphaNum,
      email,
      newProjectData: {},
      companyUpdateTimes : 0,
      rawCompany: "",
      rawContract: "",
      customerOptions: [],
    }
  },
  methods:{
    editProject: function(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
            this.newProjectData.projectName = this.newProjectData.project_name
            this.newProjectData.projectId = this.newProjectData.project_id
            this.newProjectData.customerBm = this.newProjectData.customer_bm
            this.newProjectData.customerPm = this.newProjectData.customer_pm
            this.isSaving = false
            this.$axios.post("/collab/pm/edit_project",this.newProjectData).then((res)=>{
            this.isSaving = true

           if (res.data.status == "ok"){
            this.clearForm()
            this.$emit("get-project-data")
            this.$emit("get-timeline")
            this.$emit('update:is-edit-project-sidebar-active', false)
           }
          }) 
        }
      })
    
    },
    getSysContacts: function(){
      this.customerOptions = []
      var query = "?company_id=" + this.newProjectData.company
      this.$axios.get("crm/contacts/system/get_contacts" + query).then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.contacts.forEach((contact)=>{
            this.customerOptions.push({label:contact.last_name + contact.first_name, value:contact.contact_id,company:contact.company_name,title:contact.title})
          })
        }
      })
    },
    clearForm: function(){
      this.$refs.refFormObserver.reset()
      this.newProjectData = {}
    },
  },
  watch:{
    'isEditProjectSidebarActive':{
      handler: function(val){
        if(val){
          this.newProjectData = JSON.parse(JSON.stringify(this.projectData))
          this.projectData.customer_bm.forEach((bm)=>{
            this.newProjectData.customer_bm.push(bm.id)
          })
          this.rawCompany = this.projectData.company
          this.rawContract = this.projectData.contract
          this.rawCustomerBm = this.projectData.customer_bm
          this.rawCustomerPm = this.projectData.customer_pm
        }
      }
    },
    'newProjectData.company': function(val){
      this.$emit('get-contracts',val)
      this.getSysContacts()
      this.companyUpdateTimes ++
      if(this.companyUpdateTimes > 1){
        this.newProjectData.contract = null
        this.newProjectData.customer_bm = null
        this.newProjectData.customer_pm = null
      }    
      if(val == this.rawCompany){
        this.newProjectData.customer_bm = this.rawCustomerBm
        this.newProjectData.customer_pm = this.rawCustomerPm
        this.newProjectData.contract = this.rawContract
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation()

    return {
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
