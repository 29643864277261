import { render, staticRenderFns } from "./EditStatementSidebar.vue?vue&type=template&id=fd9bc796&"
import script from "./EditStatementSidebar.vue?vue&type=script&lang=js&"
export * from "./EditStatementSidebar.vue?vue&type=script&lang=js&"
import style0 from "./EditStatementSidebar.vue?vue&type=style&index=0&id=fd9bc796&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports