<template>
  <b-card>
      <!-- User Info: Left col -->
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start">
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ projectData.project_name }} 
              </h4>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                class="mr-1"
                variant="primary"
                @click="$emit('edit-project')"
              >
                编辑
              </b-button>

              <b-button
              variant="outline-danger"
                @click="$emit('del-project', projectData.project_id)"
              >
                删除
              </b-button>
            </div>
          </div>
        </div>

        <!-- User Stats -->

      <b-card-body>
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-hashtag"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">项目编号</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ projectData.project_id }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-home"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">公司</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ projectData.company_name }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-clock"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">创建时间</span>
            </th>
            <td class="pb-50">
              {{ projectData.time_cn }}
            </td>
          </tr>

          <tr>
            <th class="pb-50 text-nowrap">
              <font-awesome-icon
                icon="fa-solid fa-map-pin"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">阶段</span>
            </th>
            <td class="pb-50">
              {{ mapPeriod(projectData.kanban) }}
            </td>
          </tr>

          <tr>
            <th class="pb-50 text-nowrap">
              <font-awesome-icon
                icon="fa-solid fa-file-signature"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">合同</span>
            </th>
            <td class="pb-50">
              <a class="text-primary" @click="getContractFile(projectData.contract,projectData.contract_name)">
                <font-awesome-icon v-if="projectData.contract"
                icon="fa-solid fa-paperclip"
                size="lg"
                class="mr-75"/>{{ projectData.contract_name }}</a>
            </td>
          </tr>
        </table>
      </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,BCardBody,BModal,BCardText
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BCardBody,BModal,BCardText
  },
  directives: {
    Ripple,
  },
  props: {
    emailContent: {
      type: Array,
      default: () => [],
    },
    projectData: {
      type: Object,
      required: true,
    },
  },
  methods:{
    mapPeriod(kanban){
      if (kanban == 0) return "评估中"
      else if (kanban == 1) return "商务沟通"
      else if (kanban == 2) return "执行中"
      else if (kanban == 3) return "已结项"
      else if (kanban == 4) return "取消"
    },
    delContact(){
      this.$axios.get("/crm/contacts/system/del_contact/" + this.$route.params.id ).then((response) => {
        this.$router.go(-1)
      })
    },

    getContractFile(contractId,contractName){
        this.$axios.get("/crm/companies/system/download_contract/" + contractId).then((res)=>{
          if (res.data.status == "ok") {
            const url = this.$backend + '/download_by_token/' + res.data.data.token
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', contractName)
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
        })
    },
    delConfirm() {
      this.$swal({
        title: '删除联系人',
        text: "删除后无法撤销，你确定要删除该联系人吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.delContact()
        }
      })
    },
  },

  setup() {
    //const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      //resolveUserRoleVariant,
    }
  },
}
</script>

<style>

</style>
