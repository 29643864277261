<template>
  <b-card-actions action-collapse class="b-card" title="报价评估单">
    <template v-if="hasCostData">
    <b-card-body>
      <b-row class="mb-1">
        <b-col>
          <span class="mr-1">最新评估单 {{rawData.newest_table ? rawData.newest_table.time_cn : "" }}</span>
        </b-col>
        <b-col>
          <div class="d-flex flex-row-reverse">
            <b-button class="ml-2" v-if="priceTableData.priceTable.length && !sameWithRaw" :disabled="!addFinished" variant="primary" @click="addCostTable">保存</b-button>
            <b-button  v-if="Object.keys(rawData.newest_table).length" :disabled="!addFinished && !rawData.newest_table" variant="primary" @click="genQuotation">生成报价单</b-button>
          </div>
        </b-col>
      </b-row>
      <h6 class="text-center">报价评估单</h6>
        <b-alert
            v-if="olderThanCost"
            variant="warning"
            show
          >
            <div class="alert-body">
              <span>最新的报价评估单时间早于成本评估单,</span>
              <a @click="alignCost" class="text-primary ml-1">拉取最新成本评估单数据</a>
            </div>

        </b-alert>
          
      <div class="d-flex justify-content-center" style="overflow:scroll">
        <b-table class="mb-2" v-if ="priceTableData.priceTable.length"
          :bordered="true"
          :items="priceTableData.priceTable"
          :fields="fields"
        >

        <template #cell(price)="data">
          <b-form-input no-wheel class="table-input" v-model="data.item.price" type="number"/>
        </template>

        <template #cell(profit)="data">
          <b-badge v-if="data.item.price && data.item.cost" variant="light-primary">
          {{ ((data.item.price - data.item.cost)/data.item.price * 100).toFixed(2) }}%
          </b-badge>
        </template>

        <template #cell(note)="data">
          <b-form-input class="table-input" v-model="data.item.note" type="text"/>
        </template>
        </b-table>
        <p v-else>暂无数据，
          <a class="text-primary" @click="alignCost">拉取最新成本评估单数据</a>
        </p>
      </div>
      <h6 class="text-center">准确率标准</h6>
      <div class="d-flex justify-content-center" style="overflow:scroll">
        <b-table v-if ="priceTableData.rateTable.length"
          :bordered="true"
          :items="priceTableData.rateTable"
          :fields="rateFields"
        />
        <p v-else>暂无数据</p>
      </div>


      <hr>
      <span>历史评估单</span>
      <app-collapse v-if="historyTables.length" accordion type="margin">
         <template v-for="(table,index) in this.historyTables">
          <app-collapse-item :title="table.time_cn">
            <h6 class="text-center">报价评估单</h6>
            <div class="d-flex justify-content-center text-center" style="overflow:scroll">
              <b-table  :bordered="true" responsive="sm" :fields="historyFields" :items="historyTables[index].price_table">
                <template #cell(profit)="data">
                  <b-badge v-if="data.item.price && data.item.cost" variant="light-primary">
                    {{ ((data.item.price - data.item.cost)/data.item.price * 100).toFixed(2) }}%
                  </b-badge>
                </template>
              </b-table>
            </div>

            <h6 class="text-center">准确率标准</h6>
            <div class="d-flex justify-content-center text-center" style="overflow:scroll">
              <b-table  :bordered="true" responsive="sm" :fields="historyRateFields" :items="historyTables[index].rate_table"/>
            </div>

          </app-collapse-item>
        </template>
      </app-collapse>
      <div v-else class="d-flex justify-content-center" style="overflow:scroll">
        <p >暂无数据</p>
      </div> 

    </b-card-body>
  </template>

    <template v-else>
      <div class="d-flex justify-content-center mb-2">
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body">
                <span>请先添加成本评估单</span>
              </div>
            </b-alert>
          </div>
    </template>
  </b-card-actions>
</template>

<script>
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import BEditableTable from "bootstrap-vue-editable-table";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

import {
  BBadge,
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BCard,
  BDropdown,
  BCardBody,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BAlert,
} from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BCardBody,
    BFormGroup,
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    AppCollapseItem,
    AppCollapse,
    BCardActions,
    BAlert,
  BFormInput,
  },
  data() {
    return {
      olderThanCost: false,
      addFinished: true,
      fields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "成本单价（元）",
        },
        {
          key: "price",
          label: "报价单价（元）",
        },
        {
          key: "profit",
          label: "利润率",
        },
        {
          key: "note",
          label: "备注",
        },
      ],
      rateFields: [
        {
          key: "element",
          label: "元素",
        },
        {
          key: "rate",
          label: "准确率（%）",
        },
        { key: "note", 
        label: "备注" },
      ],
      historyFields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "成本单价（元）",
        },
        {
          key: "price",
          label: "报价单价（元）",
        },
        {
          key: "profit",
          label: "利润率",
        },
        {
          key: "note",
          label: "备注",
        },
      ],
      historyRateFields:[
      {
          key: "element",
          label: "元素单位",
        },
        {
          key: "rate",
          label: "准确率",
        },
        {
          key: "note",
          label: "备注",
        },
      ],
      priceTableData: {
        projectId: this.$route.params.id,
        priceTable: [],
        rateTable: [],
      },
      historyTables: [],
      sameWithRaw: true,
    }
  },
  computed:{
    hasCostData(){
      if (this.costData.newest_table.cost_table.length){
        return true
    }else{
        return false
      }
    }
  },
  props:{
    rawData:{
      type: Object,
      default: function(){
        return {
          newest_table: {
            price_table: [],
            rate_table: []
          },
          histories: []
        }
      }
    },
    costData:{
      type: Object,
      default: function(){
        return {
          newest_table: {},
          histories: []
        }
      }
    }
  },
  methods: {
    alignCost(){
      this.priceTableData.priceTable = []
      this.priceTableData.rateTable = []
      this.costData.newest_table.cost_table.forEach((row)=>{
        this.priceTableData.priceTable.push({
          element: row.element,
          cost: row.cost,
          note: row.note,
          price: "",
          profit: "",
        })
      })
      this.costData.newest_table.rate_table.forEach((row)=>{
        this.priceTableData.rateTable.push({
          element: row.element,
          rate: row.rate,
          note: row.note,
        })
      })
    },
    restore(){
      this.priceTableData.priceTable = JSON.parse(JSON.stringify(this.rawData.newest_table.price_table))
    },
    genQuotation: function(){
      this.$router.push({name: "collab-pm-quotation-view", params: {id: this.$route.params.id}})
    },
    addCostTable: function(){
      var hasCost = true
        this.priceTableData.priceTable.forEach((row)=>{
          if (!row.price) {
            hasCost = false
            this.$toast.error("必须填写报价单价")
          }
        })
        if(!hasCost) return
        this.addFinished = false;
        this.$axios.post("/collab/pm/add_price_table", this.priceTableData).then(() => {
        this.addFinished = true;
        this.$emit('refresh-price-tables')
        this.$emit("get-timeline")
      });
    },
    addRow(){
      this.priceTableData.priceTable.push({
        id: Date.now(),
        element: "",
        cost: "",
        price:"",
        profit:"",
        note: "",
      })
    },
    delRow(id){
      this.priceTableData.priceTable = this.priceTableData.priceTable.filter((item)=>{
        return item.id !== id
      })
    },
  },
  watch:{
    rawData:{
      handler: function(val){
        this.priceTableData.priceTable = JSON.parse(JSON.stringify(this.rawData.newest_table.price_table))
        this.priceTableData.rateTable = JSON.parse(JSON.stringify(this.rawData.newest_table.rate_table))

        this.historyTables = JSON.parse(JSON.stringify(this.rawData.histories))

        if(val.newest_table.time < this.costData.newest_table.time){
          this.olderThanCost = true
        }else{
          this.olderThanCost = false
        }
      },
      deep: true
    },
    costData:{
      handler: function(val){
        if(val.newest_table.time > this.rawData.newest_table.time){
          this.olderThanCost = true
        }else{
          this.olderThanCost = false
        }
      },
    },

    priceTableData:{
      handler: function(val){
        if(JSON.stringify(this.rawData.newest_table.price_table) == JSON.stringify(this.priceTableData.priceTable)){
          this.sameWithRaw = true
        }else{
          this.sameWithRaw = false
        }
      },
      deep: true
    },
  },
};
</script>

<style>

.table-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px
}
</style>