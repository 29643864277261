<template>
  <section>
    <template v-if="this.hasSow">
    <b-row class="mb-1">
        <b-col>
          <div class="d-flex flex-row-reverse">
            <!-- <b-button class="ml-2"  variant="primary" @click="activeUploadSidebar">上传已签署的任务书</b-button> -->  
          
          <b-button variant="primary" @click="$emit('gen-statement')">生成结算单</b-button>
          </div>
        </b-col>
      </b-row>
    <b-card-body>
      
      <div class="d-flex justify-content-center mb-2" style="overflow:scroll" v-if="!statements.length">
        <span >暂无数据</span>
      </div>

      <div v-else class="table-responsive">
      <b-table :fields="fields" :items="statements" class="text-nowrap">
        <template #cell(statement_name)="data">
          <a 
            v-b-tooltip.hover.right="data.item.statement_name"
            class="text-primary"
            @click="loadFile(data.item.statement_id,data.item.statement_name)">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.statement_name.slice(0, 10) + '...' }}
          </a>
        </template>
    

        <template #cell(sign_status)="data">
          <b-badge v-if="data.item.sign_status" variant="light-success">
            双方已签署
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未经双方签署
          </b-badge>
        </template>

        <template #cell(invoice_status)="data">
          <b-badge
          
            @click="downloadInvoice(data.item.invoice_file_id, data.item.invoice_name)"
            href="#"
            v-if="data.item.invoice_status" variant="light-success"
            >
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            有发票
          </b-badge>

          <b-badge v-else variant="light-secondary">
            无发票
          </b-badge>
        </template>

        <template #cell(payment_status)="data">
          <b-badge
            v-b-modal.modal-vendor-payment-detail
            href="#"
            v-if="data.item.vendor_payment_status == 'approved'" variant="light-success"
            @click="checkPaymentDetail(data.item.vendor_payment_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            已通过
          </b-badge>

          <b-badge 
            v-b-modal.modal-vendor-payment-detail
            href="#"
            v-else-if="data.item.vendor_payment_status == 'pending'" variant="light-primary"
            @click="checkPaymentDetail(data.item.vendor_payment_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            审批中
          </b-badge>

          <b-badge 
            v-else-if="data.item.vendor_payment_status == 'rejected'" variant="light-danger"
            v-b-modal.modal-vendor-payment-detail
            href="#"
            @click="checkPaymentDetail(data.item.vendor_payment_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            已驳回
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未申请
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item 
              @click = "raisePaymentApproval({
                  statementId: data.item.statement_id,
                  companyId: data.item.company_id,
                  summary: data.item.summary
                  })"
              :disabled="data.item.vendor_payment_status == 'approved' || data.item.vendor_payment_status == 'pending'"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">发起付款审批流</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="activeEditStatementSidebar({
                statementId: data.item.statement_id,
                statementName: data.item.statement_name,
                signStatus: data.item.sign_status,
                invoiceId: data.item.invoice_id,
                invoiceName: data.item.invoice_name,
                invoiceStatus: data.item.invoice_status,
              })"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item 
              @click = "delStatement(data.item.statement_id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </div>
    
    </b-card-body>
  </template>

    <template v-if="!hasSow">
    <b-card-body>
      <div class="d-flex justify-content-center mb-2">
        <b-alert show variant="warning">
          <div class="alert-body">
            <span>请先签署项目任务书</span>
          </div>
        </b-alert>
      </div>
    </b-card-body>
  </template>

  <b-modal
      centered
      ok-only
      ok-title="关闭"
      id="modal-vendor-payment-detail"
      :title="checkingApproval.time_cn"
      cancel-variant="outline-secondary"
    >
      <b-card-text
        v-for="i in Object.keys(checkingApprovalData)">
        <span class="mr-1">{{ i }}：</span>
        <span >{{ checkingApprovalData[i] }}</span>
      </b-card-text>

      <hr>

      <b-card-text>
        <app-timeline v-if="Object.keys(checkingApproval.approval_data).length">
          <app-timeline-item
          v-for="workflow in checkingApproval.workflow_status"
            :variant="mapTimelineVariant(workflow.id)"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>{{ workflow.name }}</h6>
            <!--  <small class="text-muted">12 min ago</small> -->
            </div>
            <b-badge v-for="member in workflow.members" class="mr-1" :variant="mapMemberVariant(member,workflow.id)">
              {{mapUserName(member)}}
            </b-badge>
            <small>
              <template v-if="mapNote(workflow.id)">
                备注：{{ mapNote(workflow.id) }}
              </template>
            </small>
          </app-timeline-item>
        </app-timeline>
      </b-card-text>
  </b-modal>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import BEditableTable from "bootstrap-vue-editable-table";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VendorContactEditSidebar from './VendorContactEditSidebar.vue'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BCard,
  BDropdown,
  BCardBody,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BBadge,
  BTab,
  BTabs,
  BAlert,
  VBTooltip,
  BModal, 
  VBModal,
  BCardText,
  
} from "bootstrap-vue";
export default {
  components: {
    VendorContactEditSidebar,
    BBadge,
    BCardBody,
    BFormGroup,
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    AppCollapseItem,
    AppCollapse,
    BCardActions,
    BFormInput,
    BTab,
    BTabs,
    BAlert,
    AppTimeline,
    AppTimelineItem,
    BModal, 
    VBModal,
    BCardText
  },
  directives:{
    'b-tooltip': VBTooltip,
  },
  props:{
    hasSow: false,
    companyId:null,
    statements:{
      type: Array,
      default: () => []
    },
    users:{
      type: Array,
      default: () => []
    }
  },
  computed:{
    filteredSows(){
      return this.sows.filter(sow=>sow.company_id == this.companyId)
    },
    checkingApprovalData: function(){
      var data = JSON.parse(JSON.stringify(this.checkingApproval.approval_data))
      Object.keys(data).forEach((control) => {
        if(typeof(data[control]) != "string"){
          if(!Array.isArray(data[control])){
            data[control] = data[control]["value"]
          }else{
            var temp = []
            data[control].forEach((item) => {
              temp.push(item["value"])
            })
            data[control] = temp.join("；")
          }
      }
      })
      return data
    }
  },
  methods:{
    delStatement(statementId){
      this.$axios.get('/collab/pm/del_vendor_statement/' + statementId).then(resp =>{
        if(resp.data.status=="ok"){
          this.$emit('get-vendor-statements')
        }
      })
    },
    checkPaymentDetail(paymentLaunchId){
      this.$axios.get('/collab/approvals/get_launched_approval/' + paymentLaunchId).then(resp =>{
        if(resp.data.status=="ok"){
          this.checkingApproval = resp.data.data.launched_detail
        }
      })
    },
    raisePaymentApproval(data){
      this.$emit('raise-payment-approval',data)
    },
    activeEditStatementSidebar(statementData){
      this.$emit('active-edit-statement-sidebar')
      this.$emit('set-editing-statement-data',statementData)
    },    
    downloadInvoice(invoiceFileId,invoiceName){
      this.$axios.get('/collab/pm/download_vendor_invoice/' + invoiceFileId).then(res=>{
        if (res.data.status == "ok"){
          const url = this.$backend + '/download_by_token/' + res.data.data.token
          const link = document.createElement('a')
          link.setAttribute('href', url)
          link.setAttribute('download', invoiceName)
          document.body.appendChild(link)
          link.click()
          link.remove()
        }
      })
    },
    loadFile(statementId,fileName){
        this.$axios.get("/collab/pm/download_vendor_statement/" + statementId).then(res=>{
          if (res.data.status == "ok"){
            const url = this.$backend + '/download_by_token/' + res.data.data.token
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
        })
    },
    mapTimelineVariant(workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.status == "pending"){
        var v = "primary"
      }else if(workflow.status == "approved"){
        var v = "success"
      }else if(workflow.status == "rejected"){
        var v = "danger"
      }else if(workflow.status == null){
        var v = null
      }
      return v
    },
    mapMemberVariant(user,workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.member_status.approved.includes(user)){
        var v = "light-success"
      }else if(workflow.member_status.rejected.includes(user)){
        var v = "light-danger"
      }else if(workflow.member_status.pending.includes(user)){
        var v = "light-primary"
      }
      return v
    },
    mapNote(workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.note){
        return workflow.note
      }else{
        return false
      }
    },
    mapUserName(userId){
      var user = this.users.find((user) => {
        return user.user_id == userId
      })
      return user.user_name
    },
  },
  data(){
    return{
      checkingApproval:{
        approval_data: {}
      },
      fields:[
        //{"key":"sow_id","label":"任务书编号"},
        {"key":"user_name","label":"上传用户"},
        {"key":"statement_name","label":"结算单文件"},
        {"key":"summary","label":"金额（元）"},
        {"key":"sign_status","label":"双方签署状态"},
        {"key":"invoice_status","label":"发票状态"},
        {"key":"payment_status","label":"付款状态"},
        {"key":"actions","label":"操作"}
      ]
    }
  },
};
</script>

<style>

.table-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px
}
</style>