<template>
  <section>
    <template v-if="this.hasCostData && this.hasVendorBm && this.hasVendorPm && this.hasValidContract && this.hasGuideline">
    <b-row class="mb-1">
        <b-col>
          <div class="d-flex flex-row-reverse">
            <!-- <b-button class="ml-2"  variant="primary" @click="activeUploadSidebar">上传已签署的任务书</b-button> -->  
          
          <b-button variant="primary" @click="$emit('gen-sow')">生成任务书</b-button>
          </div>
        </b-col>
      </b-row>
    <b-card-body>
      
      <div class="d-flex justify-content-center mb-2" style="overflow:scroll" v-if="!sows.length">
        <span >暂无数据</span>
      </div>

      <div v-else class="table-responsive">
      <b-table :fields="fields" :items="filteredSows" class="text-nowrap">
        <template #cell(sow_name)="data">
          <a 
            class="text-primary"
            @click="loadFile(data.item.sow_id,data.item.sow_name)">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.sow_name }}
          </a>
        </template>
      
        <template #cell(email_status)="data">
          <b-badge v-if="data.item.email_status" variant="light-success">
            已发送
          </b-badge>
        </template>

        <template #cell(sign_status)="data">
          <b-badge v-if="data.item.sign_status" variant="light-success">
            双方已签署
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未经双方签署
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="activeEditSowSidebar({
                sowId: data.item.sow_id,
                sowName: data.item.sow_name,
                signStatus: data.item.sign_status,
              })"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="deleteSow(data.item.sow_id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    
    </b-card-body>
  </template>

    <template v-if="!hasValidContract">
    <b-card-body>
      <div class="d-flex justify-content-center mb-2">
        <b-alert show variant="warning" >
          <div class="alert-body">
            <span>请先签署有效的框架合同</span>
          </div>
        </b-alert>
      </div>    
    </b-card-body>
  </template>

  <template v-else-if="!hasGuideline">
    <b-card-body>
      <div class="d-flex justify-content-center mb-2">
        <b-alert show variant="warning" >
          <div class="alert-body">
            <span>请先添加项目规则</span>
          </div>
        </b-alert>
      </div>    
    </b-card-body>
  </template>

    <template v-else-if="!hasCostData">
    <b-card-body>
      <div class="d-flex justify-content-center mb-2">
        <b-alert show variant="warning" >
          <div class="alert-body">
            <span>请先添加定价单</span>
          </div>
        </b-alert>
      </div>    
    </b-card-body>
  </template>

  <template v-else-if="!hasVendorPm">
    <b-card-body>
      <div class="d-flex justify-content-center mb-2">
        <b-alert show variant="warning" >
          <div class="alert-body">
            <span>请先添加供应商项目联系人</span>
          </div>
        </b-alert>
      </div>    
    </b-card-body>
  </template>

  <template v-else-if="!hasVendorBm">
    <b-card-body>
      <div class="d-flex justify-content-center mb-2">
        <b-alert show variant="warning" >
          <div class="alert-body">
            <span>请先添加供应商商务联系人</span>
          </div>
        </b-alert>
      </div>    
    </b-card-body>
  </template>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import BEditableTable from "bootstrap-vue-editable-table";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VendorContactEditSidebar from './VendorContactEditSidebar.vue'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BCard,
  BDropdown,
  BCardBody,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BBadge,
  BTab,
  BTabs,
  BAlert
  
} from "bootstrap-vue";
export default {
  components: {
    VendorContactEditSidebar,
    BBadge,
    BCardBody,
    BFormGroup,
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    AppCollapseItem,
    AppCollapse,
    BCardActions,
    BFormInput,
    BTab,
    BTabs,
    BAlert
  },
  props:{
    hasVendorPm:false,
    hasVendorBm:false,
    hasCostData:false,
    hasValidContract:false,
    hasGuideline:false,
    companyId:null,
    sows:{
      type: Array,
      default: () => []
    }
  },
  computed:{
    filteredSows(){
      return this.sows.filter(sow=>sow.company_id == this.companyId)
    }
  },
  methods:{
    activeEditSowSidebar(sowData){
      this.$emit('active-edit-sow-sidebar')
      this.$emit('set-editing-sow-data',sowData)
    },    
    loadFile(sowId,fileName){
        this.$axios.get("/collab/pm/download_vendor_sow/" + sowId).then(res=>{
          if(res.data.status == "ok") {
            const url = this.$backend + '/download_by_token/' + res.data.data.token
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
        })
    },
    deleteSow(sowId){
      this.$axios.get("/collab/pm/del_vendor_sow/" + sowId).then(res=>{
        if(res.data.status == "ok") {
          this.$emit('get-vendor-sows')
        }
      })
    },
    
  },
  data(){
    return{
      fields:[
        //{"key":"sow_id","label":"任务书编号"},
        {"key":"user_name","label":"上传用户"},
        {"key":"sow_name","label":"任务书文件"},
        {"key":"sign_status","label":"双方签署状态"},
        {"key":"actions","label":"操作"}
      ]
    }
  },
};
</script>

<style>

.table-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px
}
</style>