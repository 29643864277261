<template>
  <b-sidebar
    id="edit-sow-sidebar"
    :visible="isEditSowSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-edit-sow-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          编辑项目任务书
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <b-form
          class="p-2"
          @submit.prevent="upload()"
          @reset.prevent="resetForm"
        >
          <b-form-group
              label="双方签署的任务书"
              label-for="sow"
            >
            <b-form-file
              accept=".pdf"
              v-model="bothSignedSow"
              :placeholder="bothSignedSowPlaceholder"
            />
            
            </b-form-group>

            <b-form-group
              label="快递单号"
              label-for="deliveryCode"
            >
              <b-form-input
                v-model="deliveryCode"
                id="deliveryCode"
              />
            </b-form-group>


          <div class="d-flex mt-2">
            <b-button
              :disabled="isSaving"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
            保存
            </b-button>
           
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BFormTextarea,BFormFile,BSpinner,BBadge
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { temp } from '@/@core/directives/animations'
import flatPickr from 'vue-flatpickr-component'
import { ref } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormFile,
    BFormTextarea,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BSpinner,
    flatPickr,
    temp,
    BBadge
},
  directives: {
    Ripple,
  },
  model: {
    prop: 'isEditSowSidebarActive',
    event: 'update:is-edit-sow-sidebar-active',
  },
  props: {
    isEditSowSidebarActive: {
      type: Boolean,
      required: true,
    },
    sowData: {
      "sowId":null,
      "sowName":null,
      "deliveryCode":null,
      "signStatus": null,
    },
  },

  data() {
    return {
      isSaving: false,
      bothSignedSow: null,
      deliveryCode:null,
    }
  },
  methods:{
    upload(){
      var formData = new FormData()
      if (this.bothSignedSow){
        formData.append('both_signed_sow', this.bothSignedSow)
      }
      formData.append('project_id', this.$route.params.id)
      formData.append('sow_id',this.sowData.sowId)
      formData.append('delivery_code',this.deliveryCode)
      this.isSaving = true
      this.$axios.post('/collab/pm/edit_sow',formData).then(res=>{
        this.isSaving = false

        if(res.data.status == "ok")
          this.clearForm()
          this.$emit('refresh-sow')
      })
    },
    clearForm: function(){
      this.$emit('update:is-edit-sow-sidebar-active', false)
      this.$refs.refFormObserver.reset()
      this.bothSignedSow = null
      this.deliveryCode = null
    },
  },
  watch:{
    "sowData":{
      handler:function(val){
        this.sowId = JSON.parse(JSON.stringify(val.sowId))
        if (val.deliveryCode){
          this.deliveryCode = JSON.parse(JSON.stringify(val.deliveryCode))
        }else{
          this.deliveryCode = ""
        }
      },
      deep:true
    }
  },
  computed:{
    bothSignedSowPlaceholder(){
      if (this.sowData.signStatus){
        return this.sowData.sowName
      }else{
        return "请选择或拖拽文件至此（仅支持pdf格式）..."
      }
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
