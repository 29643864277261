<template>
  <b-card>
    <b-card-title>联络信息</b-card-title>
    <b-card-body>
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="12"
        xl="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">项目经理</span>
            </th>
            <td class="pb-50">
            </td>
          </tr>
          <b-badge variant="light-primary" v-for="pm in projectData.pm" :key="'pm' + pm" class="mb-1 mr-1">
            {{ userMapping(pm) }}
          </b-badge>

          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">商务经理</span>
            </th>
            <td class="pb-50" >
            </td>
          </tr>
          <b-badge variant="light-primary" v-for="bm in projectData.bm" :key="'bm' + bm" class="mb-1 mr-1">
            {{ userMapping(bm) }}
          </b-badge>
        
        </table>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">客户项目联系人</span>
            </th>
            <td class="pb-50">
            </td>
          </tr>
          <b-badge 
            :href="`/crm/contacts/view/${pm.id}`"
            variant="light-primary" 
            v-for="pm in projectData.customer_contact_info.customer_pm" 
            :key="'pm' + pm"
            class="mb-1 mr-1"
            >
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            {{ pm.name }}
          </b-badge>

          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">客户商务联系人</span>
            </th>
            <td class="pb-50" >
            </td>
          </tr>
          <b-badge 
            variant="light-primary" 
            :href="`/crm/contacts/view/${bm.id}`"
            v-for="bm in projectData.customer_contact_info.customer_bm" 
            :key="'bm' + bm" 
            class="mb-1 mr-1"
          >
          <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            {{ bm.name }}
          </b-badge>
        </table>
      </b-col>

<!--       <b-col
        cols="12"
        xl="4"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-brands fa-weixin"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">微信对接群</span>
            </th>
            <td class="pb-50">
            </td>
          </tr>
          <b-badge 
            v-if="projectData.wechat_group"
            variant="light-primary" 
            class="mb-1 mr-1"
            >
            {{ projectData.wechat_group}}
          </b-badge>
        </table>
      </b-col> -->

    </b-row>


    
  </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'

export default {
  components: {
    BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data(){
    return{
    }
  },
  props:{
    projectData: {
      type: Object,
      default: () => {}
    },
    users: {
      type: Array,
      default: () => []
    },
  },
  methods:{
    userMapping(id){
      if (this.users.find(user => user.user_id === id)){
        return this.users.find(user => user.user_id === id).user_name
      }
    }, 
    
  },
  setup() {
    return {
      avatarText
    }
  },
}
</script>

<style>

</style>
