<template>
  <section>
    <b-modal
      id="modal-history-receivers"
      ok-only
      ok-title="取消"
      ok-variant="primary"
      centered
      title="历史接收信息"
    >
    <b-card 
      v-if="historyReceivers.length"
      border-variant="secondary" 
      bg-variant="transparent"
      v-for="i in historyReceivers">
        <p>收件人：{{i.receiver}}</p>
        <p>收件电话：{{i.receiver_phone}}</p>
        <p>收件地址：{{i.receiver_address}}</p>
        <p>收件Email：{{i.receiver_email}}</p>
        <a class="text-primary" @click="insertReceiverInfo({
          receiver: i.receiver,
          receiver_phone: i.receiver_phone,
          receiver_address: i.receiver_address,
          receiver_email: i.receiver_email
        })">选择</a>
    </b-card>

    <div class="d-flex justify-content-center" v-else>
      暂无数据
    </div>
    </b-modal>

  <b-sidebar
    id="raise-invoice-sidebar"
    :visible="isRaiseInvoiceSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-raise-invoice-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          发起发票审批流
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
          ref="refFormObserver"
        >
        <b-form
          class="p-2"
          @submit.prevent="raiseInvoice()"
          @reset.prevent="clearForm">
            <validation-provider v-for="(control,index) in controls"
              #default="{ errors }"
              :name="control.name"
              :rules="mapRules(control.type,control.required)"
            >
              <b-form-group
                :label="control.name"
                :label-for="control.name"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-if="control.type != `singleSelect` && control.type != `multiSelect`"
                  v-model="newApproval[control.name]"
                  :id="control.id"
                  @wheel.prevent
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :state="errors.length > 0 ? false:null"
                  :ref="control.name"
                />

                <v-select
                  v-else
                  v-model="newApproval[control.name]"
                    :id="control.id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="control.options.map(option => ({ text: option, value: option }))"
                    :multiple="control.type == `multiSelect`"
                    label="text"
                />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>

              </b-form-group>
            </validation-provider>

            <a class="text-primary" @click="getHistoryReceivers"  v-b-modal.modal-history-receivers v-if="invoiceData.company_id">
              查看历史接收信息
            </a>

            <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >保存
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</section>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BSpinner,BBadge,BFormRadio, BModal,VBModal,BCardText,BCard
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required,integer } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormRadio,
    BModal,BCardText,BCard
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isRaiseInvoiceSidebarActive',
    event: 'update:is-raise-invoice-sidebar-active',
  },
  props: {
    raisingStatementId: {
      type: String,
      required: true,
      default: () => '',
    },
    controls: {
      type: Array,
      required: true,
      default: () => [],
    },
    isRaiseInvoiceSidebarActive: {
      type: Boolean,
      required: true,
    },
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      integer,
      newApproval: {},
      taxRateOptions:[
        {text:'6%',value:'6%'},
      ],
      invoiceTypeOptions:[
        {text:'增值税专用发票',value:'增值税专用发票'},
      ],
      contacts:[],
      contactOptions:[],
      historyReceivers:[],
    }
  },
  methods:{
    getCompanyContacts(){
      this.$axios.get('/crm/companies/system/get_contacts/' + this.invoiceData.company_id).then(resp =>{
        this.contacts = resp.data.data.contacts
        this.contacts.forEach(contact => {
          this.contactOptions.push({
            text:contact.last_name + contact.first_name,
            value:contact.contact_id})
        })
      })
    },
    mapRules(type,required){
      let rules = {}
      if(required) rules.required = true
      if(type == "number") {
        rules.integer = true
      }else if(type == "price"){
        let regex = new RegExp("^[0-9]+(.[0-9]{1,2})?$")
        rules.regex = regex
      }
      return rules
    },
    raiseInvoice(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          this.newApproval.projectId = this.$route.params.id
          this.$axios.post('/collab/approvals/raise_invoice_approval', this.newApproval).then((resp) => {
            if (resp.data.status=="ok"){
              this.clearForm()
              this.$emit('update:is-raise-invoice-sidebar-active', false)
              this.$emit('refresh-statements')
            }
          })
        }
      })
    },
    clearForm(){
      this.newApproval = {}
      this.$refs.refFormObserver.reset()
    },
    getHistoryReceivers(){
      this.$axios.get('/collab/pm/get_company_history_invoice_receivers/' + this.invoiceData.company_id).then(resp =>{
        this.historyReceivers = resp.data.data.receivers
      })
    },
    insertReceiverInfo(receiverInfo){
      this.newApproval.收件人 = receiverInfo.receiver
      this.newApproval.收件电话 = receiverInfo.receiver_phone
      this.newApproval.收件地址 = receiverInfo.receiver_address
      this.newApproval.收件邮箱 = receiverInfo.receiver_email

      this.$root.$emit('bv::hide::modal', 'modal-history-receivers')
      this.$refs.收件人[0].focus()
      this.$refs.收件人[0].blur()
      this.$refs.收件电话[0].focus()
      this.$refs.收件电话[0].blur()
      this.$refs.收件地址[0].focus()
      this.$refs.收件地址[0].blur()
      this.$refs.收件邮箱[0].focus()
      this.$refs.收件邮箱[0].blur()
    }
  },
  watch:{
    "isRaiseInvoiceSidebarActive":function(val){
      if(val){
        this.getCompanyContacts()

        this.raiseInvoiceData = JSON.parse(JSON.stringify(this.invoiceData))
        this.controls.forEach(control => {
          this.$set(this.newApproval,control.name,control.type == `multiSelect`?[]:``)
        })
        this.newApproval.发票抬头 = this.invoiceData.name
        this.newApproval.税号 = this.invoiceData.tax_number
        this.newApproval.单位地址 = this.invoiceData.address
        this.newApproval.开户银行 = this.invoiceData.opening_bank
        this.newApproval.银行账户 = this.invoiceData.bank_account
        this.newApproval.公司电话 = this.invoiceData.telephone
        this.newApproval.开票金额 = this.invoiceData.summary
        this.newApproval.税率 = this.invoiceData.tax_rate
        this.newApproval.发票类型 = this.invoiceData.invoice_type
        this.newApproval.statementId = this.raisingStatementId
      }
    },
  },

  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
