<template>
  <section>
    <div class="d-flex flex-row-reverse">
      <b-button class="" @click="$emit('active-vendor-contact-edit-sidebar')" variant="primary">编辑</b-button>
    </div>

    <div class="d-flex justify-content-center text-nowrap">
      <b-row>
        <b-col cols="12" xl="6">
          <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">供应商商务联系人</span>
            </th>
            <td class="pb-50" v-if="filteredVendorContacts">
              <b-badge class="mr-1" v-for = "vbm in filteredVendorContacts.vendor_bm" variant="light-primary">
                {{ contactMapping(vbm) }}
              </b-badge>
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <font-awesome-icon
                icon="fa-solid fa-user-tie"
                size="lg"
                class="mr-75"/>
              <span class="font-weight-bold">供应商项目联系人</span>
            </th>
            <td class="pb-50" v-if="filteredVendorContacts">
              <b-badge class="mr-1" v-for = "vpm in filteredVendorContacts.vendor_pm" variant="light-primary">
                {{ contactMapping(vpm) }}
              </b-badge>
            </td>
          </tr>

        </table>

        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import BEditableTable from "bootstrap-vue-editable-table";
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import VendorContactEditSidebar from './VendorContactEditSidebar.vue'
import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BCard,
  BDropdown,
  BCardBody,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  BBadge,
  BTab,
  BTabs,
  BAlert
  
} from "bootstrap-vue";
export default {
  components: {
    VendorContactEditSidebar,
    BBadge,
    BCardBody,
    BFormGroup,
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    AppCollapseItem,
    AppCollapse,
    BCardActions,
    BFormInput,
    BTab,
    BTabs,
    BAlert
  },
  data() {
    return {
    
    }
  },
  props:{
    filteredVendorContacts: {
      type: Object,
      default: null
    },
    contactOptions: {
      type: Array,
      default: null
    },
  },
  methods: {
    contactMapping(contactId){
      var contact = this.contactOptions.find((contact)=>{
        return contact.value == contactId
      })
      if(contact){
        return contact.label
      }
    },
  },
  created(){

  },
};
</script>

<style>

.table-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px
}
</style>