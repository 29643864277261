import { render, staticRenderFns } from "./CostTable.vue?vue&type=template&id=480f3faa&scoped=true&"
import script from "./CostTable.vue?vue&type=script&lang=js&"
export * from "./CostTable.vue?vue&type=script&lang=js&"
import style0 from "./CostTable.vue?vue&type=style&index=0&id=480f3faa&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "480f3faa",
  null
  
)

export default component.exports