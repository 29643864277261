<template>
  <b-card-actions  action-collapse title="结算单">
    <template v-if="this.hasPriceData && this.hasContract && this.hasGuideline && this.hasCustomerBm && this.hasCustomerPm && this.relateCompany">
    <b-row class="mb-1">
        <b-col>
          <div class="d-flex flex-row-reverse">
            <b-button variant="primary" @click="genStatement">生成新结算单</b-button>
          </div>
        </b-col>
      </b-row>
    <b-card-body>
      

      <div class="d-flex justify-content-center mb-2" style="overflow:scroll" v-if="!statements.length">
        <span >暂无数据</span>
      </div>

      <div v-else class="table-responsive">
      <b-table :fields="fields" :items="statements" class="text-nowrap">
        
        <template #cell(statement_name)="data">
          <a 
            v-b-tooltip.hover.right="data.item.statement_name"
            class="text-primary"
            @click="loadFile(data.item.statement_id,data.item.statement_name)">
            <font-awesome-icon
              icon="fa-solid fa-paperclip"
            />
            {{ data.item.statement_name.slice(0, 10) + '...' }}
          </a>
        </template>

        <template #cell(sign_status)="data">
          <b-badge v-if="data.item.sign_status" variant="light-success">
            双方已签署
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未经双方签署
          </b-badge>
        </template>

        <template #cell(email_status)="data">
          <b-badge v-if="data.item.email_status" variant="light-success">
            已发送
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未发送
          </b-badge>
        </template>

        <template #cell(invoice_status)="data">
          <b-badge
            v-b-modal.modal-customer-invoice-detail
            href="#"
            v-if="data.item.invoice_status == 'approved'" variant="light-success"
            @click="checkInvoiceDetail(data.item.invoice_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            已通过
          </b-badge>

          <b-badge 
            v-b-modal.modal-customer-invoice-detail
            href="#"
            v-else-if="data.item.invoice_status == 'pending'" variant="light-primary"
            @click="checkInvoiceDetail(data.item.invoice_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            审批中
          </b-badge>

          <b-badge 
            v-else-if="data.item.invoice_status == 'rejected'" variant="light-danger"
            v-b-modal.modal-customer-invoice-detail
            href="#"
            @click="checkInvoiceDetail(data.item.invoice_launch_id)">
            <feather-icon
              icon="LinkIcon"
              class="mr-25"
            />
            已驳回
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未申请
          </b-badge>
        </template>

        <template #cell(payment_status)="data">
          <b-badge v-if="data.item.payment_status" variant="light-success">
            已回款
          </b-badge>

          <b-badge v-else variant="light-secondary">
            未回款
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item
              @click="sendStatementEmail(data.item.statement_id)"
            >
              <feather-icon icon="MailIcon"/>
              <span class="align-middle ml-50">发送结算邮件</span>
            </b-dropdown-item>

            <b-dropdown-item
              :disabled="data.item.invoice_status == 'approved' || data.item.invoice_status == 'pending'"
              @click="raiseInvoice(data.item.statement_id,data.item.sign_status)"
            >
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">发起发票审批流</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="activeEditSidebar({
                statementId: data.item.statement_id,
                statementName: data.item.statement_name,
                deliveryCode: data.item.delivery_code,
                signStatus: data.item.sign_status
              })"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item
              @click="delStatement(data.item.statement_id)"
            >
              <feather-icon icon="TrashIcon"/>
              <span class="align-middle ml-50">删除</span>
            </b-dropdown-item>

          </b-dropdown>
        </template>
      </b-table>
    </div>
    
    <raise-invoice-sidebar
      :invoice-data="invoiceData"
      :is-raise-invoice-sidebar-active.sync="isRaiseInvoiceSidebarActive"
      :controls="raiseInvoiceControls"
      :raising-statement-id="raisingStatementId"
      @refresh-statements="refreshStatements"
    />

    <b-modal
      centered
      ok-only
      ok-title="关闭"
      id="modal-customer-invoice-detail"
      :title="checkingApproval.time_cn"
      cancel-variant="outline-secondary"
    >
      <b-card-text
        v-for="i in Object.keys(checkingApprovalData)">
        <span class="mr-1">{{ i }}：</span>
        <span >{{ checkingApprovalData[i] }}</span>
      </b-card-text>

      <hr>

      <b-card-text>
        <app-timeline v-if="Object.keys(checkingApproval.approval_data).length">
          <app-timeline-item
          v-for="workflow in checkingApproval.workflow_status"
            :variant="mapTimelineVariant(workflow.id)"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <h6>{{ workflow.name }}</h6>
            <!--  <small class="text-muted">12 min ago</small> -->
            </div>
            <b-badge v-for="member in workflow.members" class="mr-1" :variant="mapMemberVariant(member,workflow.id)">
              {{mapUserName(member)}}
            </b-badge>
            <small>
              <template v-if="mapNote(workflow.id)">
                备注：{{ mapNote(workflow.id) }}
              </template>

              <template v-if="mapNote(workflow.id) && mapDeliveryCode(workflow.id)">
                ；
              </template>

              <template v-if="mapDeliveryCode(workflow.id)">
                快递单号：{{ mapDeliveryCode(workflow.id) }}
              </template>
            </small>
          </app-timeline-item>
        </app-timeline>
      </b-card-text>
    </b-modal>

    </b-card-body>
    </template>

    <template v-else>
    <b-card-body>
      <div class="d-flex justify-content-center mb-2">
            <b-alert
              variant="warning"
              show
            >
              <div class="alert-body">
                <span v-if="!this.hasContract">请先绑定框架合同</span>
                <span v-else-if="!this.hasGuideline">请先添加项目规则</span>
                <span v-else-if="!this.hasPriceData">请先添加报价评估单</span>
                <span v-else-if="!this.hasCustomerBm">请先添加客户商务联系人</span>
                <span v-else-if="!this.hasCustomerPm">请先添加客户项目联系人</span>
              </div>
            </b-alert>
          </div>    
        </b-card-body>
  </template>
  </b-card-actions>
</template>


<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import {
  BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox,BAvatar,BButton, BCollapse,VBToggle,BCardText,BFormInput,BMedia,BMediaAside,BMediaBody,BSpinner,
  BRow,BCol,BBadge,BDropdown,BDropdownItem,BModal, VBModal,VBTooltip,BAlert
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { avatarText } from '@core/utils/filter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import RaiseInvoiceSidebar from './RaiseInvoiceSidebar.vue'

export default {
  components: {
    BAlert,AppTimeline,AppTimelineItem, BModal,RaiseInvoiceSidebar, BCardActions,BDropdown,BDropdownItem,BCard, BTable, BCardBody, BCardTitle, BCardSubTitle, BFormCheckbox, BAvatar,BButton,BCollapse,BCardText,BFormInput,  BRow,BCol,BMedia,BMediaAside,BMediaBody,BSpinner,BBadge
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-modal': VBModal,
    'b-tooltip': VBTooltip,
  },
  data(){
    return{
      checkingApproval: { //发票审批流详情
        approval_data: {}
      },
      invoiceData: {
        tax_rate: '6%',
        invoice_type: '增值税专用发票'
      },
      raisingStatementId: '',
      raiseInvoiceControls:[],
      isRaiseInvoiceSidebarActive:false,
      fields:[
        {"key":"user_name","label":"上传用户"},
        {"key":"statement_name","label":"结算单文件"},
        {"key":"summary","label":"金额（元）"},
        {"key":"delivery_code","label":"快递单号"},
        {"key":"sign_status","label":"双方签署状态"},
        {"key":"email_status","label":"邮件发送状态"},
        {"key":"invoice_status","label":"发票状态"},
        {"key":"payment_status","label":"回款状态"},
        {"key":"payment_bank_track_number","label":"银行流水号"},
        {"key":"actions","label":"操作"}
      ]
    }
  },
  computed:{
    checkingApprovalData: function(){
      var data = JSON.parse(JSON.stringify(this.checkingApproval.approval_data))
      Object.keys(data).forEach((control) => {
        if(typeof(data[control]) != "string"){
          if(!Array.isArray(data[control])){
            data[control] = data[control]["value"]
          }else{
            var temp = []
            data[control].forEach((item) => {
              temp.push(item["value"])
            })
            data[control] = temp.join("；")
          }
      }
      })
      return data
    }
  },
  props:{
    statements: {
      type: Array,
      default: () => {
        return ([])
      },
    },
    relateCompany: {
      type: String,
      default: () => {
        return ('')
      },
    },
    users:{
      type: Array,
      default: () => {
        return ([])
      },
    },
    hasPriceData: false,
    hasContract: false,
    hasGuideline:false,
    hasCustomerPm:false,
    hasCustomerBm:false,
},
  methods:{
    delStatement(statementId){
      this.$axios.get('collab/pm/del_statement/' + statementId).then(resp =>{
        if (resp.data.status == "ok"){
          this.refreshStatements()
        }
      })
    },
    getApprovalData(){
      this.$axios.get("collab/approvals/get_system_approval/INVOICE_APPROVAL").then(resp =>{
        this.approvalData = resp.data.data.approval
      })
    },
    mapUserName(userId){
      var user = this.users.find((user) => {
        return user.user_id == userId
      })
      return user.user_name
    },
    checkInvoiceDetail(invoiceLaunchId){
      this.$axios.get('/collab/approvals/get_launched_approval/' + invoiceLaunchId).then(resp =>{
        if(resp.data.status=="ok"){
          this.checkingApproval = resp.data.data.launched_detail
        }
      })
    },
    refreshStatements(){
      this.$emit('refresh-statements')
    },
    sendStatementEmail(statementId){
      this.$router.push({
        name:'collab-pm-statement-mail-view',
        params:{projectId:this.$route.params.id,statementId:statementId}})
    },
    raiseInvoice(statementId,signStatus){

        this.raisingStatementId = statementId
        this.invoiceData.summary = this.statements.filter(statement => statement.statement_id == statementId)[0].summary.replace(/,/g,'')
        this.getRaiseInvoiceControls()
        this.getCompanyInfoAndActiveSidebar()
      
    },
    getCompanyInfoAndActiveSidebar(){
      this.$axios.get('/crm/companies/system/get_company/' + this.relateCompany).then(resp=>{
        if(resp.data.status == "ok"){
          this.invoiceData = {...resp.data.data.company, ...this.invoiceData}
          this.isRaiseInvoiceSidebarActive = true
        }
      })
    },
    getRaiseInvoiceControls(){
      this.$axios.get('/collab/approvals/get_raise_invoice_approval').then(resp =>{
        if(resp.data.status == "ok"){
          this.raiseInvoiceControls = resp.data.data.approval[0].controls
        }
      })
    },
    activeEditSidebar(statementData){
      this.$emit('active-edit-statement-sidebar')
      this.$emit('set-editing-statement-data',statementData)
    },
    genStatement(){
      this.$router.push({name: "collab-pm-statement-view", params: {id: this.$route.params.id}})
    },
    loadFile(statementId,fileName){
        this.$axios.get("/collab/pm/download_statement/" + statementId).then(res=>{
          if (res.data.status == "ok"){
            const url = this.$backend + '/download_by_token/' + res.data.data.token
            const link = document.createElement('a')
            link.setAttribute('href', url)
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            link.remove()
          }
        })
    },
    mapTimelineVariant(workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.status == "pending"){
        var v = "primary"
      }else if(workflow.status == "approved"){
        var v = "success"
      }else if(workflow.status == "rejected"){
        var v = "danger"
      }else if(workflow.status == null){
        var v = null
      }
      return v
    },
    mapMemberVariant(user,workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.member_status.approved.includes(user)){
        var v = "light-success"
      }else if(workflow.member_status.rejected.includes(user)){
        var v = "light-danger"
      }else if(workflow.member_status.pending.includes(user)){
        var v = "light-primary"
      }
      return v
    },
    mapNote(workflowId){
      var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.note){
        return workflow.note
      }else{
        return false
      }
    },
    mapDeliveryCode(workflowId){
    var workflow = this.checkingApproval.workflow_status.find((workflow) => {
        return workflow.id == workflowId
      })
      if(workflow.delivery_code){
        return workflow.delivery_code
      }else{
        return false
      }
  }
  },
  mounted(){
    this.getApprovalData()
  },
}
</script>

<style>

</style>
