<template>
  <div>

    <project-edit
      :contract-options="contractOptions"
      :user-options="userOptions"
      :company-options="companyOptions"
      :project-data="projectData"
      :is-edit-project-sidebar-active.sync ="isEditProjectSidebarActive"
      @get-project-data="getProjectData"
      @get-timeline="getTimeline"
      @get-contracts="getContracts($event)"
    />

    <template v-if="projectData">
      <b-row class="match-height">
        <b-col
          cols="12"
          lg="6"
        >
        <project-info-card
          :project-data="projectData" 
          @edit-project="editProject" 
          @del-project="delProject"
          />
        </b-col>

        <b-col
          cols="12"
          lg="6"
        >
        <contact-card  
          :project-data="projectData"
          :users="users"
          @get-project-data="getProjectData"/>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <timeline-card 
            :timeline-data="timelineData"
            @get-timeline="getTimeline"
            />
        </b-col>

      </b-row>

      <guideline-card
        :guideline-data="guidelineData"
        @active-upload-guideline-sidebar="isUploadGuidelineSidebarActive = true"
        @get-timeline="getTimeline"
        @refresh-guidelines="getGuidelines"
        />

      <b-tabs align="center" pills>
      <b-tab
        title="商务信息"
        active
      >
      <cost-table
        :raw-data="costTableData"
        @refresh-cost-tables="getCostTables"
        @get-timeline="getTimeline"
        />
      
      <pricing-table
        :raw-data="priceTableData"
        :cost-data="costTableData"
        @refresh-price-tables="getPriceTables"
        @get-timeline="getTimeline"
        />

      <sow-card
        :has-price-data="hasPriceData"
        :has-contract="hasContract"
        :has-guideline="hasGuideline"
        :has-customer-bm="hasCustomerBm"
        :has-customer-pm="hasCustomerPm"
        :sow-data="sowData"
        @active-edit-sow-sidebar="isEditSowSidebarActive = true"
        @get-timeline="getTimeline"
        @refresh-sow="getSows"
        @set-editing-sow-data="setEditingSowData($event)"
        />

      <statement-card
        :has-price-data="hasPriceData"
        :has-contract="hasContract"
        :has-guideline="hasGuideline"
        :has-customer-bm="hasCustomerBm"
        :has-customer-pm="hasCustomerPm"
        :users="users"
        :relate-company="projectData.company"
        :sow-data="sowData"
        :statements = "statements"
        @active-edit-statement-sidebar="isEditStatementSidebarActive = true"
        @set-editing-statement-data="setEditingStatementData($event)"
        @refresh-statements="getStatements"
        />
    </b-tab>

      <b-tab title="供应商管理">
        <div class="d-flex justify-content-center" v-if="!projectData.vendors.length && hasNewestPriceTable">
          <p>暂无数据,</p>
          <a class="text-primary" @click="isVendorSidebarActive = true">添加供应商</a>
        </div>

        <template v-else>
          <div class="d-flex justify-content-center mb-2">
            <b-alert
              v-if="!hasNewestPriceTable"
              variant="warning"
              show
            >
              <div class="alert-body">
                <span>请先添加报价评估单</span>
              </div>
            </b-alert>
            <b-button v-else variant="primary" @click="isVendorSidebarActive = true">添加或移除供应商</b-button>
          </div>

          <vendor-card
            v-if="hasNewestPriceTable"
            v-for="vendor in projectData.vendors"
              :key="vendor"
              :company-id="vendor"
              :newest-price-data="priceTableData.newest_table.price_table"
              :vendor-contacts="projectData.vendor_contacts"
              :has-guideline="hasGuideline"
              :users="users"
              @get-timeline="getTimeline"
              @get-project ="getProjectData"
          />
        </template>
      </b-tab>
    </b-tabs>
      

      <edit-sow-sidebar
        :is-edit-sow-sidebar-active.sync="isEditSowSidebarActive"
        :sow-data="editingSowData"
        @refresh-sow="getSows"
      />

      <edit-statement-sidebar
        :is-edit-statement-sidebar-active.sync="isEditStatementSidebarActive"
        :statement-data="editingStatementData"
        @refresh-statements="getStatements"/>

      <upload-guideline-sidebar
        :is-upload-guideline-sidebar-active.sync="isUploadGuidelineSidebarActive"
        :project-data="projectData"
        @refresh-guidelines="getGuidelines"
        @get-timeline="getTimeline"
      />

      <vendor-sidebar
        :vendors="projectData.vendors"
        :is-vendor-sidebar-active.sync="isVendorSidebarActive"
        :vendor-options="vendorOptions"
        @refresh-project-data="getProjectData"/>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,BTab,BTabs,BButton
} from 'bootstrap-vue'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import userStoreModule from '../userStoreModule'
import ProjectInfoCard from './general/ProjectInfoCard.vue'
import TimelineCard from './general/TimelineCard.vue'
import ContactCard from './general/ContactCard.vue'
import ProjectEdit from './general/ProjectEditSidebar.vue'
import CostTable from './business/CostTable.vue'
import PricingTable from './business/PricingTable.vue'
import SowCard from './business/SowCard.vue'
import EmailCard from './EmailCard.vue'
import guidelineCard from './general/GuidelineCard.vue'
import EditSowSidebar from './business/EditSowSidebar.vue'
import EditStatementSidebar from './business/EditStatementSidebar.vue'
import UploadGuidelineSidebar from './general/UploadGuidelineSidebar.vue'
import VendorCard from './vendors/VendorCard.vue'
import VendorSidebar from './vendors/VendorSidebar.vue'
import StatementCard from './business/StatementCard.vue'
export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTab,
    BTabs,
    BButton,
    // Local Components
    ProjectInfoCard,
    TimelineCard,
    ContactCard,
    InvoiceList,
    ProjectEdit,
    CostTable,
    PricingTable,
    SowCard,
    EmailCard,
    guidelineCard,
    EditSowSidebar,
    EditStatementSidebar,
    UploadGuidelineSidebar,
    VendorCard,
    VendorSidebar,
    StatementCard,
  },
  data(){
    return{
      hasNewestPriceTable:false,
      costTableData: {
        "newest_table":{
          "cost_table":[],
          "rate_table":[]
        },
        "histories":[],
      },
      priceTableData: {
        "newest_table":{
          "price_table":[],
          "rate_table":[]
        },
        "histories":[],
      },
      updateByCostTable: 0,
      projectData: {
        vendors:[],
      },
      contractOptions:[],
      isEditProjectSidebarActive:false,
      isUploadSowSidebarActive:false,
      isUploadGuidelineSidebarActive:false,
      isEditSowSidebarActive:false,
      isEditStatementSidebarActive:false,
      isVendorSidebarActive:false,
      timelineData: [],
      fileDownloading: false,
      userOptions:[],
      customerOptions:[],
      companyOptions:[],
      vendorOptions:[],
      users:[],
      contacts:[],
      sowData:[],
      statements:[],
      guidelineData:[],
      vendors:[],
      hasPriceData:false,
      editingSowData: {
        "sowId":null,
        "sowName":null,
        "deliveryCode":null,
        "signStatus": null,
      },
      editingStatementData:{
        "statementId":null,
        "statementName":null,
        "deliveryCode":null,
        "signStatus":null,
      },
      hasContract: false,
      hasGuideline:false,
      hasCustomerPm:false,
      hasCustomerBm:false,
    }
  },
  methods:{
    computeRemainDays: function(dueDate){
      let due = new Date(dueDate)
      let now = new Date()
      let remain = due - now
      let days = Math.floor(remain / (24 * 3600 * 1000))
      return days
    },
    getContracts: function(companyId){
      this.contractOptions = []
      this.$axios.get("/crm/companies/system/get_contracts/" + companyId).then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.contracts.forEach((contract)=>{
            //if(contract.sign_status) 
            this.contractOptions.push({
              label:contract.contract_name,
              value:contract.contract_id,
              remain:this.computeRemainDays(contract.contract_due_date),
              signStatus:contract.sign_status
            })
          })
        }
      })
    },
    getCostTables(){
      this.$axios.get("/collab/pm/get_cost_tables/" + this.$route.params.id).then((res)=>{
        this.costTableData = res.data.data.tables
      })
    },
    getPriceTables(){
      this.$axios.get("/collab/pm/get_price_tables/" + this.$route.params.id).then((res)=>{
        this.priceTableData = res.data.data.tables
        if(Object.keys(this.priceTableData.newest_table).length){
          this.hasNewestPriceTable = true
        }
      })
    },
    getProjectData(){
      this.$axios.get("/collab/pm/get_project/" + this.$route.params.id).then((res)=>{
        if(res.data.data.project.vendors == null){
          res.data.data.project.vendors = []
        }
        this.projectData = res.data.data.project
      })
    },
    editProject(){
      this.isEditProjectSidebarActive = true;
    },
    delProject: function(projectId){
      this.$swal({
        title: '删除项目',
        text: "删除后无法撤销，你确定要删除该项目吗？",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '是的，删除！',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.get("/collab/pm/del_project/" + projectId).then((res)=>{
        if (res.data.status == "ok"){
          this.$router.push({name:"collab-pm"})
        }
      })        
    }
    })
    },
    getCompanies: function(){
      this.$axios.get("crm/companies/system/get_companies").then((res)=>{
        if (res.data.status == "ok"){
          res.data.data.companies.forEach((company)=>{
            this.companyOptions.push({label:company.name,value:company.company_id})
          })
        }
      })
    },
/*     getSysContacts: function(){
      this.$axios.get("crm/contacts/system/get_contacts").then((res)=>{
        if (res.data.status == "ok"){
          this.contacts = res.data.data.contacts;
          res.data.data.contacts.forEach((contact)=>{
            this.customerOptions.push({label:contact.last_name + contact.first_name,value:contact.contact_id,company:contact.company_name,title:contact.title})
          })
        }
      })
    }, */
    getUsers(){
      this.$axios.get("/manage/users/get_users").then((res)=>{
        if (res.data.status == "ok"){
          this.users = res.data.data.users;
          res.data.data.users.forEach((user)=>{
            this.userOptions.push({label:user.user_name,value:user.user_id})
          })
        }
      })
    },
    getTimeline(){
      this.$axios.get("/collab/pm/get_timeline/" + this.$route.params.id).then((res)=>{
      this.timelineData = res.data.data.timeline;
    })
    },
    getSows(){
      this.$axios.get("/collab/pm/get_sows/" + this.$route.params.id).then((res)=>{
        if(res.data.status == "ok"){
          this.sowData = res.data.data.sows;
        }
      })
    },
    getStatements(){
      this.$axios.get('/collab/pm/get_statements/' + this.$route.params.id).then((resp =>{
        this.statements = resp.data.data.statements
      }))
    },
    getGuidelines(){
      this.$axios.get("/collab/pm/get_guidelines/" + this.$route.params.id).then((res)=>{
        if(res.data.status == "ok"){
          this.guidelineData = res.data.data.guidelines;
        }
      })
    },
    getVendorOptions(){
      this.$axios.get("/vendors/companies/get_companies").then((res)=>{
        if(res.data.status == "ok"){
          res.data.data.companies.forEach((company)=>{
            this.vendorOptions.push({label:company.name,value:company.company_id})
          })
        }
      })
    },
    setEditingSowData(sowData){
      this.editingSowData = JSON.parse(JSON.stringify(sowData))
    },
    setEditingStatementData(statementData){
      this.editingStatementData = JSON.parse(JSON.stringify(statementData))
    },
},
  created(){
    this.getPriceTables()
    this.getCostTables()
    this.getProjectData()
    this.getTimeline()
    this.getUsers()
    this.getCompanies()
    //this.getSysContacts()
    this.getSows()
    this.getGuidelines()
    this.getVendorOptions()
    this.getStatements()
  },
  computed:{
    priceOlderThanCost(){
        if (this.priceTableData.newest_table.time > this.costTableData.newest_table.time){
          return false;
        }else{
          return true;
        }
    }
  },
  watch:{
    "priceTableData":{
      handler(val){
        if(Object.keys(val.newest_table).length){
          if(val.newest_table.price_table.length){
            this.hasPriceData = true;
          }
        }else{
          this.hasPriceData = false
        }
    },
      deep:true
    },
    "projectData":{
      handler(val){
        localStorage.setItem('projectName', this.projectData.project_name)
        localStorage.setItem('customerCompanyName', this.projectData.company_name)
        if(val.contract){
          this.hasContract = true;
        }else{
          this.hasContract = false;
        }
        
        if(val.customer_bm.length){
          this.hasCustomerBm = true
        }else{
          this.hasCustomerBm = false
        }

        if(val.customer_pm.length){
          this.hasCustomerPm = true
        }else{
          this.hasCustomerPm = false
        }
      },
      deep:true
    },
    "guidelineData":{
      handler(val){
        if(val.length){
          this.hasGuideline = true
        }else{
          this.hasGuideline = false
        }
      },
    }
  },
  onMouted() {

  },
  setup() {
    const userData = ref(null)

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response.data })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })
    return {
      userData,
    }
  },
}
</script>

<style>

</style>
