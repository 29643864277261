<template>
  <b-card-actions action-collapse class="b-card" title="成本评估单">
    <b-row align-h="start">
      <b-col cols="12" md="auto">
      </b-col>
    </b-row>

    <b-card-body>
      <b-row>
        <b-col>
          <span class="mr-1">最新评估单 {{rawData.newest_table ? rawData.newest_table.time_cn : "" }}</span>
        </b-col>
        <b-col>
          <div class="d-flex flex-row-reverse">
            <b-button v-if="costTableData.costTable.length && !sameWithRawData" :disabled="!addFinished" variant="primary" @click="addCostTable">保存</b-button>
          </div>
        </b-col>
      </b-row>

      <h6 class="text-center">成本评估单</h6>
      <div class="table-responsive">
        <b-table
          v-if ="costTableData.costTable.length"
          :bordered="true"
          :items="costTableData.costTable"
          :fields="fields"
        >
        <template #cell(element)="data">
          <b-form-input class="table-input" v-model="data.item.element" type="text"/>
        </template>

        <template #cell(cost)="data">
          <b-form-input no-wheel class="table-input" v-model="data.item.cost" type="number"/>
        </template>

        <template #cell(note)="data">
          <b-form-input class="table-input" v-model="data.item.note" type="text"/>
        </template>

        <template #cell(edit)="data">
          <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              
              <b-dropdown-item @click="addRow">
                <feather-icon icon="PlusIcon" />
                <span class="align-middle ml-50">添加一行</span>
              </b-dropdown-item>

              <b-dropdown-item @click="delRow(data.item.id)">
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">删除</span>
              </b-dropdown-item>
          </b-dropdown>
        </template>
        </b-table>
      </div>
      
      <div class="d-flex justify-content-center" style="overflow:scroll">
        <p v-if ="!costTableData.costTable.length">暂无数据,<a class="text-primary" @click="addRow">添加新数据</a>
        <p v-if="rawData.newest_table.cost_table.length && !costTableData.costTable.length">或</p>
        <a v-if="rawData.newest_table.cost_table.length && !costTableData.costTable.length" class="text-primary" @click="restore">撤销删除</a></p>
      </div>

      <h6 class="text-center">准确率标准</h6>
      <div class="table-responsive" v-if ="costTableData.rateTable.length">
          <b-table
            :bordered="true"
            :items="costTableData.rateTable"
            :fields="rateFields"
          >
          <template #cell(element)="data">
            <b-form-input class="table-input" v-model="data.item.element" type="text"/>
          </template>
          <template #cell(rate)="data">
            <b-form-input no-wheel class="table-input" v-model="data.item.rate" type="number"/>
          </template>
          <template #cell(note)="data">
            <b-form-input class="table-input" v-model="data.item.note" type="text"/>
          </template>
          <template #cell(edit)="data">
            <b-dropdown
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>
                
                <b-dropdown-item @click="addRateRow">
                  <feather-icon icon="PlusIcon" />
                  <span class="align-middle ml-50">添加一行</span>
                </b-dropdown-item>

                <b-dropdown-item @click="delRateRow(data.item.id)">
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">删除</span>
                </b-dropdown-item>
            </b-dropdown>
          </template>
          </b-table>
      </div>
          <div class="d-flex justify-content-center" style="overflow:scroll">
          <p v-if="!costTableData.rateTable.length">暂无数据,<a class="text-primary" @click="addRateRow">添加新数据</a>
            <p v-if="rawData.newest_table.rate_table.length && !costTableData.rateTable.length">或</p>
            <a v-if="rawData.newest_table.rate_table.length && !costTableData.rateTable.length" class="text-primary" @click="restoreRate">撤销删除</a></p>
        </div>
      
      <hr>
      <span>历史评估单</span>
      <app-collapse v-if="historyTables.length" accordion type="margin">
         <template v-for="(table,index) in this.historyTables">
          <app-collapse-item :title="table.time_cn">
            <h6 class="text-center">成本评估单</h6>
            <div class="table-responsive">
              <b-table  :bordered="true" responsive="sm" :fields="historyFields" :items="historyTables[index].cost_table"/>
            </div>

            <h6 class="text-center">准确率标准</h6>
            <div class="table-responsive">
              <b-table  :bordered="true" responsive="sm" :fields="historyRateFields" :items="historyTables[index].rate_table"/>
            </div>
          </app-collapse-item>
        </template>
      </app-collapse>
      <div v-else class="d-flex justify-content-center" style="overflow:scroll">
        <p >暂无数据</p>
      </div> 

    </b-card-body>
  </b-card-actions>
</template>

<script>
import BEditableTable from "bootstrap-vue-editable-table";
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

import {
  BIconTrash,
  BIconPencil,
  BIconX,
  BIconCheck,
  BButton,
  BCard,
  BDropdown,
  BCardBody,
  BDropdownItem,
  BRow,
  BCol,
  BTable,
  BFormInput,
  BFormGroup,
  
} from "bootstrap-vue";
export default {
  components: {
    BCardActions,
    BCardBody,
    BFormGroup,
    BEditableTable,
    BIconX,
    BIconTrash,
    BIconPencil,
    BIconCheck,
    BButton,
    BCard,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
    BTable,
    AppCollapseItem,
    AppCollapse,
  BFormInput,
  },
  data() {
    return {
      addFinished: true,
      fields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "成本单价（元）",
        },
        {
          key: "note",
          label: "备注",
        },
        { 
          key: "edit", 
          label: "操作" 
        },
        
      ],
      rateFields: [
        {
          key: "element",
          label: "元素",
        },
        {
          key: "rate",
          label: "准确率（%）",
        },
        {
          key: "note",
          label: "备注",
        },
        { key: "edit", 
        label: "操作" },
      ],
      historyFields: [
        {
          key: "element",
          label: "元素单位",
        },
        {
          key: "cost",
          label: "成本单价",
        },
        {
          key: "note",
          label: "备注",
        },
      ],
      historyRateFields:[
      {
          key: "element",
          label: "元素单位",
        },
        {
          key: "rate",
          label: "准确率",
        },
        {
          key: "note",
          label: "备注",
        },
      ],
      costTableData: {
        projectId: this.$route.params.id,
        costTable: [],
        rateTable: [],
      },
      historyTables: [],
      sameWithRawData: true,
    }
  },
  props:{
    rawData:{
      type: Object,
      default: function(){
        return {
          "newest_table": {
            "cost_table": [],
            "rate_table": []
          },
          "histories": []
        }
      }
    }
  },
  methods: {
    restore(){
      this.costTableData.costTable = JSON.parse(JSON.stringify(this.rawData.newest_table.cost_table))
    },
    restoreRate(){
      this.costTableData.rateTable = JSON.parse(JSON.stringify(this.rawData.newest_table.rate_table))
    },
    addRow(){
      this.costTableData.costTable.push({
        id: Date.now(),
        element: "",
        cost: "",
        note: "",
      })
    },
    delRow(id){
      this.costTableData.costTable = this.costTableData.costTable.filter((item)=>{
        return item.id !== id
      })
    },
    addRateRow(){
      this.costTableData.rateTable.push({
        id: Date.now(),
        element: "",
        rate: "",
        note: "",
      })
    },
    delRateRow(id){
      this.costTableData.rateTable = this.costTableData.rateTable.filter((item)=>{
        return item.id !== id
      })
    },
    addCostTable: function(){
      if(this.costTableData.rateTable.length){
        this.addFinished = false;
        this.$axios.post("/collab/pm/add_cost_table", this.costTableData).then((resp) => {
        this.addFinished = true;
        this.$emit('refresh-cost-tables')
        this.$emit("get-timeline")
      });
    }else{
      this.$toast.error("必须填写准确率标准")
    }  
  },
  },
  watch:{
    rawData:{
      handler: function(){
        this.costTableData.costTable = JSON.parse(JSON.stringify(this.rawData.newest_table.cost_table))
        this.costTableData.rateTable = JSON.parse(JSON.stringify(this.rawData.newest_table.rate_table))
        this.historyTables = JSON.parse(JSON.stringify(this.rawData.history_tables))
      },
      deep: true
    },
    costTableData:{
      handler: function(){
        if(JSON.stringify(this.costTableData.costTable) === JSON.stringify(this.rawData.newest_table.cost_table) && JSON.stringify(this.costTableData.rateTable) === JSON.stringify(this.rawData.newest_table.rate_table)){
          this.sameWithRawData = true
        }else{
          this.sameWithRawData = false
        }
        },
      deep: true
    }
  },
  computed:{
    hasRawDataNewestTable(){
      return this.rawData.newest_table.cost_table.length > 0
    },
  }
};
</script>

<style scoped>
.table-input {
    padding: 0.438rem 1rem;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px;
    min-width:100%
}

th{
  white-space: inherit;
}

td {
    padding: 0 !important;
}
</style>